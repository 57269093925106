div.favorite-list-item {
  border-top: 1px solid;
  border-color: #ddd;
}

div.favorite-list-header,
div.favorite-list-item {
  display: grid;
  grid-template-columns: 4.5rem 1fr 3fr 1fr 1fr 1fr 8rem;
  .item-no {
    grid-column: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 2 / 4;
    padding: 5px 5px 5px 5px;
  }
  .item-app-category {
    grid-column: 4 / 5;
    padding: 5px 5px 5px 5px;
  }
  .item-updated-at {
    grid-column: 5 / 6;
    padding: 5px 5px 5px 5px;
  }
  .item-updater-name {
    grid-column: 6 / 7;
    padding: 5px 5px 5px 5px;
  }
  .item-import-btn {
    grid-column: 7 / 8;
    padding: 5px 5px 5px 5px;
  }
  .favorite-list-detail-content {
    grid-column: 1 / 8;
  }
}

div.favorite-detail-header {
  background-color: #f5f5f5;
  font-weight: 600;
  border-bottom: solid 1px #ddd;
}

div.favorite-detail-header {
  display: grid;
  grid-template-columns: 4.5rem 1fr 3fr 1fr 1fr 1fr 8rem;
  font-size: 14px;

  .item-image-holder {
    grid-column: 1 / 2;
    padding: 4px 4px 4px 4px;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker {
    grid-column: 2 / 3;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 3 / 4;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 4 / 5;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 5 / 6;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 6 / 7;
    padding: 5px 5px 5px 5px;
  }
  .item-amount {
    grid-column: 7 / 8;
    padding: 5px 5px 5px 5px;
  }
  .detail-cell {
    border-bottom: 1px dashed;
    border-color: #ddd;
    &.last-detail-cell {
      border-bottom: none;
    }
  }
}

div.favorite-detail-body {
  display: grid;
  grid-template-columns: 4.5rem 1fr 3fr 1fr 1fr 1fr 8rem;
  font-size: 14px;

  .favorite-list-item-div {
    grid-column: 1 / -1;

    display: grid;
    grid-template-columns: 4.5rem 1fr 3fr 1fr 1fr 1fr 8rem;
    .item-image-holder {
      grid-column: 1 / 2;
      padding: 4px 4px 4px 4px;
      img.item-image {
        // 画像サイズはgridの幅に合わせる
        width: 4rem;
        height: 4rem;
        object-fit: cover;
      }
    }
    .item-maker {
      grid-column: 2 / 3;
      padding: 5px 5px 5px 5px;
    }
    .item-name {
      grid-column: 3 / 4;
      padding: 5px 5px 5px 5px;
    }
    .item-model-number {
      grid-column: 4 / 5;
      padding: 5px 5px 5px 5px;
    }
    .item-product-code {
      grid-column: 5 / 6;
      padding: 5px 5px 5px 5px;
    }
    .item-spec {
      grid-column: 6 / 7;
      padding: 5px 5px 5px 5px;
    }
    .item-amount {
      grid-column: 7 / 8;
      padding: 5px 5px 5px 5px;
    }
    .detail-cell {
      border-bottom: 1px dashed;
      border-color: #ddd;
      &.last-detail-cell {
        border-bottom: none;
      }
    }

  }
}
