div.purc-appr-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  // 部署別の文言を　入れるためno.列を3remにしている
  grid-template-columns: 3rem 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column-start: 1;
    grid-row: 1 / 3;
    padding: 5px 5px 5px 5px;
  }
  .item-reject {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 5 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 9 / 10;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
}

// 商品の情報と、部署ごとの情報を含んだdiv
div.purc-appr-item-wrapper {
  border-bottom: solid 1px #ddd;
  @media only screen and (max-width: 991px) {
    margin-top: 9px;
  }
}

// flexでは配置しきれないのでgridを使う
div.purc-appr-item-div {
  display: grid;
  @media only screen and (min-width: 992px) {
    font-size: 14px;
    // 部署別の文言を　入れるためno.列を3remにしている
    grid-template-columns: 3rem 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "item-no-t item-reject-t item-reject-t item-maker-name-t item-name-t item-name-t item-model-number-t item-product-code-t item-spec-t"
      "item-no item-reject item-image-holder item-maker-name item-name item-name item-model-number item-product-code item-spec"
      "detail detail detail detail detail detail detail detail detail";
    &> [class^=item-] {
      margin-top: 0.5rem;
    }
  }

  @media only screen and (max-width: 991px) {
    font-size: 13px;
    grid-template-columns: 35% 65%;
    grid-template-rows: auto;
    grid-template-areas:
      "item-no-t item-no"
      "item-reject-t item-reject"
      "item-image-holder-t item-image-holder"
      "item-maker-name-t item-maker-name"
      "item-name-t item-name"
      "item-model-number-t item-model-number"
      "item-product-code-t item-product-code"
      "item-spec-t item-spec"
      "detail detail";
  }

  /* no, img, maker, name, model_number, product_code */
  padding: 0 0 0.5rem 0;

  .grid-title {
    background-color: #f5f5f5;
    border-bottom: solid 1px #ddd;
  }
  .item-no {
    padding: 5px 5px 5px 5px;
    @media only screen and (min-width: 992px) {
      padding: 20px 5px 0px 0px;
      text-align: center;
    }
    grid-area: item-no;
    &.grid-title{
      grid-area: item-no-t;
      margin-top: 0;
      text-align: unset;
    }
  }
  .item-reject {
    padding: 5px 5px 5px 5px;
    @media only screen and (min-width: 992px) {
      padding: 20px 5px 0px 0px;
      text-align: center;
    }
    grid-area: item-reject;
    &.grid-title{
      grid-area: item-reject-t;
      margin-top: 0;
      text-align: unset;
    }
  }
  .item-image-holder {
    @media only screen and (max-width: 991px) {
      padding: 5px 5px 5px 5px;
    }
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
    grid-area: item-image-holder;
    &.grid-title{
      grid-area: item-image-holder-t;
      margin-top: 0;
    }
  }
  .item-maker-name {
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
    grid-area: item-maker-name;
    &.grid-title{
      grid-area: item-maker-name-t;
      margin-top: 0;
    }
  }
  .item-name {
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
    grid-area: item-name;
    &.grid-title{
      grid-area: item-name-t;
      margin-top: 0;
    }
  }
  .item-model-number {
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
    grid-area: item-model-number;
    &.grid-title{
      grid-area: item-model-number-t;
      margin-top: 0;
    }
  }
  .item-product-code {
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
    grid-area: item-product-code;
    &.grid-title{
      grid-area: item-product-code-t;
      margin-top: 0;
    }
  }
  .item-spec {
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
    grid-area: item-spec;
    &.grid-title{
      grid-area: item-spec-t;
      margin-top: 0;
    }
  }

  .purc-appl-details-div {
    grid-area: detail;

    display: grid;
    @media only screen and (min-width: 992px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: repeat(3, auto);
      grid-template-areas:
        "item-regular-price item-redemption-price item-unit-number application-item-amount"
        "application-item-supplier-empty item-unit-pirce-change-date application-item-unit-pirce application-item-total-pirce"
        "application-departments application-departments application-departments application-departments";
    }
    @media only screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        "item-regular-price"
        "item-redemption-price"
        "item-unit-number"
        "application-item-amount"
        "application-item-supplier-empty"
        "item-unit-pirce-change-date"
        "application-item-unit-pirce"
        "application-item-total-pirce"
        "application-departments"
    }
    padding-top: 5px;

    .item-regular-price {
      grid-area: item-regular-price;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      justify-content: space-between;
      word-break: break-all;
      padding-left: 3px;

      .grid-label {
        width: 30%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        text-align: right;
        padding: 5px 15px 5px 5px;
        width: 70%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }

      @media only screen and (max-width: 991px) {
        padding-left: 0px;
        .grid-label {
          width: 35%;
          border-bottom: none;
        }
        .grid-value {
          width: 65%;
          border-bottom: none;
          border-right: 1px solid #93B8B6;
        }
      }
    }

    .item-redemption-price {
      grid-area: item-redemption-price;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      justify-content: space-between;
      word-break: break-all;

      .grid-label {
        width: 50%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        text-align: right;
        padding: 5px 15px 5px 5px;
        width: 50%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }

      @media only screen and (max-width: 991px) {
        padding-left: 0px;
        .grid-label {
          width: 35%;
          border-bottom: none;
        }
        .grid-value {
          width: 65%;
          border-bottom: none;
          border-right: 1px solid #93B8B6;
        }
      }
    }

    .item-unit-number {
      grid-area: item-unit-number;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      justify-content: space-between;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }

      @media only screen and (max-width: 991px) {
        padding-left: 0px;
        .grid-label {
          width: 35%;
          border-bottom: none;
        }
        .grid-value {
          width: 65%;
          border-bottom: none;
          border-right: 1px solid #93B8B6;
        }
      }
    }

    .application-item-amount {
      grid-area: application-item-amount;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      justify-content: space-between;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }

      @media only screen and (max-width: 991px) {
        padding-left: 0px;
        .grid-label {
          width: 35%;
          border-bottom: none;
        }
        .grid-value {
          width: 65%;
          border-bottom: none;
          border-right: 1px solid #93B8B6;
        }
      }
    }

    .application-item-supplier {
      grid-column: 1 / 2;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      padding-left: 3px;

      .grid-label {
        width: 30%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 70%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }

      // フォームがある時
      .application-item-supplier-value {
        width: 70%;
        padding: 5px 5px 5px 5px;
        background-color: #fff;
        border-bottom: 1px solid #93B8B6;
        // セレクトボックスなのでdisplay: flexを無効にする
        // display: flex;
        // align-items: center;
      }
    }
    .application-item-supplier-empty {
      grid-area: application-item-supplier-empty;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      padding-left: 3px;

      .grid-label {
        width: 30%;
        border-bottom: 1px solid #93B8B6;
      }

      .grid-value {
        width: 70%;
        border-bottom: 1px solid #93B8B6;
      }

      @media only screen and (max-width: 991px) {
        padding-left: 0px;
        .grid-label {
          width: 35%;
        }
        .grid-value {
          width: 65%;
        }
      }
    }

    .item-unit-pirce-change-date {
      grid-area: item-unit-pirce-change-date;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      .grid-label {
        width: 50%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 50%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      @media only screen and (max-width: 991px) {
        .grid-label {
          width: 35%;
        }
        .grid-value {
          width: 65%;
          border-right: 1px solid #93B8B6;
          justify-content: right;
        }
      }
    }

    .application-item-unit-pirce {
      grid-area: application-item-unit-pirce;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      justify-content: space-between;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }

      // フォームがある時
      .application-item-unit-price-value {
        width: 60%;
        padding: 5px 5px 5px 5px;
        background-color: #fff;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }

      @media only screen and (max-width: 991px) {
        .grid-label {
          width: 35%;
        }
        .grid-value {
          width: 65%;
          border-right: 1px solid #93B8B6;
        }
      }
    }

    .application-item-total-pirce {
      grid-area: application-item-total-pirce;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      justify-content: space-between;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }

      @media only screen and (max-width: 991px) {
        .grid-label {
          width: 35%;
        }
        .grid-value {
          width: 65%;
        }
      }
    }
  }

  .application-dept-title {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
  }

  .application-departments {
    grid-area: application-departments;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    word-break: break-all;
    padding-left: 3px;

    .grid-label {
      width: 25%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 75%;
      padding: 0px;

      display: grid;
      // grid-template-columns: repeat(3, 1fr);
      grid-template-columns: repeat(1, 1fr);

      .application-item-dept {
        display: flex;
        word-break: break-all;
        // 数量ゼロなら非表示
        &.no-amount {
          display: none;
        }

        .application-item-reject {
          width: 1.4rem;
          padding: 5px 0px 5px 5px;
          color: #666666;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          background-color: #f5f5f5;
          display: flex;
          align-items: center;
        }
        .application-item-dept-name {
          width: calc(33.3% - 1.4rem);
          padding: 5px 0px 5px 1px;
          color: #666666;
          background-color: #f5f5f5;
          display: flex;
          align-items: center;
        }
        // 部署用(inputタグがある)
        .application-item-dept-amount-value {
          width: 25%;
          padding: 5px 5px 5px 5px;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: right;
          background-color: #fff;
          flex-direction: column;
        }
        // 端数用
        .application-item-dept-amount {
          width: 13.4%;
          padding: 5px 15px 5px 5px;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: right;
          background-color: #fff;
        }

        // &.application-rejected-item-dept {
        //   background-color: lightgray;
        // }
      }
      // .application-item-dept:not(:nth-child(3n)) {
      //   .application-item-dept-amount-value { border-right: 1px solid #ddd; }
      // }
      // .application-item-dept:nth-child(n + 4) {
      //   .application-item-reject { border-top: 1px solid #ddd; }
      //   .application-item-dept-name { border-top: 1px solid #ddd; }
      //   .application-item-dept-amount-value { border-top: 1px solid #ddd; }
      //   .application-item-dept-amount { border-top: 1px solid #ddd; }
      // }
    }

    @media only screen and (max-width: 991px) {
      padding-left: 0px;
      .grid-label {
        width: 35%;
      }
      .grid-value {
        width: 65%;
        .application-item-dept {
          .application-item-dept-name {
            width: calc(50% - 1.4rem);
          }
          .application-item-dept-amount-value {
            width: 50%;
          }
          .application-item-dept-amount {
            width: 50%;
          }
          &:not(:last-child) {
            div {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}


div.purc-appr-item-total-wrapper {
  display: grid;
  grid-template-columns: 3rem 2rem 4rem 1fr 1fr 1fr 1fr;
  font-size: 14px;

  @media only screen and (max-width: 991px) {
    margin-top: 9px;
  }

  .purc-appr-total-price {
    grid-column: 7 / 8;
    display: flex;
    justify-content: space-between;

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }

    @media only screen and (max-width: 991px) {
      grid-column: 1 / -1;
      .grid-label {
        width: 35%;
        border-top: 1px solid #93B8B6;
      }
      .grid-value {
        width: 65%;
        border-top: 1px solid #93B8B6;
      }
    }
  }

}
