@media only screen and (max-width: 991px) {
  .navbar-background {
    background-color: #3366cc !important;

    .logo-background {
      color: white;
    }
    .navbar-toggler .icon-bar {
      background-color: #fff !important;
    }
  }
  .mobile-d-none {
    display: none !important;
  }
  .mobile-d-inline {
    display: inline !important;
  }
  .mobile-icon {
    font-size: 20px !important;
  }
  .mobile-dept {
    position: absolute !important;
    top: 120px;
    left: 40px;
    z-index: 5;
    .mobile-dept-color {
      background-color: #282828 !important;
    }
    a {
      width: 10rem;
    }
  }
  .mobile-datatables-filter {
    padding-left: 12px;
    margin-top: 10px;
    &::placeholder {
      color: #999;
    }
  }
  .mobile-text-danger {
    color: $danger;
  }
  .mobile-btn-block {
    display: block;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .navbar-background {
    background-color: #eee !important;
  }

  .pc-d-none {
    display: none !important;
  }
}

// .modal-lg相当のものをタブレット縦でも利用できるように
@media (max-width: 1199px) {
  .modal-lg-expansion {
    max-width: 750px;
  }
}

// スマホ縦で画像添付する際にcard-headerがかぶる問題対策用
.mobile-hidden {
  visibility: visible;
}
@media (max-width: 768px) {
  .mobile-hidden {
    visibility: hidden;
  }
}
