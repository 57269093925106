// ヘッダの設定
.invoice-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem repeat(6, 1fr) 3rem;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-tax-rate {
    grid-column: 9 / 10;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
}

// 納品書毎の領域を含むdiv
div.invoice-delivery-list-wrapper {
  margin-bottom: 1rem;
}

// 納品書ヘッダ
div.invoice-delivery-list-div {
  display: grid;
  grid-template-columns: 2rem 4rem repeat(6, 1fr) 3rem;
  font-size: 14px;

  font-weight: bold;
  border-bottom: solid 1px #ddd;
  .delivery-date {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
  }
  .delivery-number {
    grid-column: 3 / 5;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
  }
}

// 商品の情報と、部署ごとの情報を含んだdiv
.invoice-item-wrapper {
  padding-bottom: 10px;
  border-bottom: solid 1px #ddd;
}

// flexでは配置しきれないのでgridを使う
div.invoice-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem repeat(6, 1fr) 3rem;

  /* no, img, maker, name, model_number, product_code */
  padding: 0.5rem 0 0.5rem 0;
  font-size: 14px;
  .item-no {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-tax-rate {
    grid-column: 9 / 10;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
  }
  /* ここまでが1行目(Noと画像だけは2行目にもかかる) */

  .grid-label {
    padding: 5px 5px 5px 5px;
    color: #666666;
  }

  .grid-value {
    padding: 5px 5px 5px 5px;
  }

  /* 2行目 */
  .item-amount {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
  }
  .item-undelivered-amount {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
  }
  .item-unit-number {
    grid-column: 5 / 6;
    grid-row: 2 / 3;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
  }
  .item-assigned-amount {
    grid-column: 6 / 7;
    grid-row: 2 / 3;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
    .item-assigned-amount-value {
      width: calc(100% - 3.5rem);
      min-width: 50px;
    }
  }
  .item-unit-price {
    grid-column: 7 / 8;
    grid-row: 2 / 3;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
  }
  .item-total-price {
    grid-column: 8 / -1;
    grid-row: 2 / 3;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    word-break: break-all;
  }
}

div.invoice-total-wrapper {
  display: grid;
  grid-template-columns: 2rem 4rem repeat(4, 1fr) 0.5fr 0.5fr 1fr 3rem;
  align-items: center;
  word-break: break-all;

  .grid-label {
    padding: 5px 5px 5px 5px;
    color: #666666;
  }
  .grid-value {
    padding: 5px 5px 5px 5px;
  }

  .invoice-subtotal-price {
    grid-column: -4 / -1;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
  }
  .invoice-total-tax-price {
    grid-column: -4 / -1;
    grid-row: 2 / 3;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
  }
  .invoice-total-price {
    grid-column: -4 / -1;
    grid-row: 3 / 4;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
  }
}
