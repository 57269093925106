// 横8列のグリッドレイアウト

// ヘッダの設定
.distribution-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem repeat(7, 1fr);
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-unit-number {
    grid-column: 9 / 10;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
}

div.distribution-btn-header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 50;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

// 削除ボタンにstickyを使う場合はその分の高さだけtopを指定する
// .form-headerを追加で設定しておく
div.distribution-header.form-header {
  top: 40px;
}

// 商品の情報と、部署ごとの情報を含んだdiv
.distribution-item-wrapper {
  padding-bottom: 10px;
  border-bottom: solid 1px #ddd;
}

// flexでは配置しきれないのでgridを使う
div.distribution-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem repeat(7, 1fr);

  /* no, img, maker, name, model_number, product_code */
  padding: 0.5rem 0 0.5rem 0;
  font-size: 14px;
  .item-no {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    text-align: center;
  }
  .item-image-holder {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-unit-number {
    grid-column: 9 / 10;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
    text-align: right;
  }
  /* ここまでが1行目(Noだけは2行目にもかかる) */

}

.distribution-details-div{
  // border-top: dashed 1px #eee; // 使用期限ごとに塊感を出すために破線で区切る(使用期限を複数登録する場合は有効にする)
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  .item-expired-at {
    grid-column: 1 / 2;
    grid-row: 1;
    border-bottom: 1px solid #93B8B6;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    word-break: break-all;

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 60%;
      padding: 5px 5px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;

      // フォームの幅を固定しない
      .bmd-form-group { width: 100%; }
    }
  }

  .item-sum-amount {
    grid-column: 2 / 3;
    grid-row: 1;
    border-bottom: 1px solid #93B8B6;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    word-break: break-all;
    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

  .item-assigned-amount {
    grid-column: 3 / 4;
    grid-row: 1;
    border-bottom: 1px solid #93B8B6;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    word-break: break-all;
    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }
    .grid-value {
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

  .distribution-item-expired-at {
    padding-top: 10px;
    grid-column: 1 / -1;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    word-break: break-all;


    .grid-label {
      width: 20%;
      padding: 0px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      .dept-add-field {
        padding: 5px 5px 5px 5px;
      }

    }

    .grid-value {
      width: 80%;
      padding: 0px;

      display: grid;

      grid-template-columns: repeat(1, 1fr);
      .distribution-item-dept {
        display: flex;
        word-break: break-all;

        .dept-name {
          width: 35%;
          padding: 5px 0px 5px 5px;
          color: #666666;
          background-color: #f5f5f5;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;
        }
        .amount {
          width: 15%;
          padding: 5px 5px 5px 5px;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: right;
          background-color: #fff;

          // フォームの幅を固定にしない
          .bmd-form-group { width: 100%; }
        }
      }

      // 2段組の場合
      // grid-template-columns: repeat(2, 1fr);

      // .distribution-item-dept:not(:nth-child(2n)) {
      //   display: flex;
      //   word-break: break-all;

      //   .dept-name {
      //     width: 75%;
      //     padding: 5px 0px 5px 5px;
      //     color: #666666;
      //     background-color: #f5f5f5;
      //     display: flex;
      //     align-items: center;
      //   }
      //   .amount {
      //     width: 25%;
      //     padding: 5px 5px 5px 5px;
      //     border-left: 1px solid #ddd;
      //     border-right: 1px solid #ddd;
      //     display: flex;
      //     align-items: center;
      //     justify-content: right;
      //     background-color: #fff;

      //     // フォームの幅を固定にしない
      //     .bmd-form-group { width: 100%; }
      //   }
      // }
      // .distribution-item-dept:nth-child(2n) {
      //   display: flex;
      //   word-break: break-all;

      //   .dept-name {
      //     width: 75%;
      //     padding: 5px 0px 5px 5px;
      //     color: #666666;
      //     background-color: #f5f5f5;
      //     display: flex;
      //     align-items: center;
      //   }
      //   .amount {
      //     width: 25%;
      //     padding: 5px 5px 5px 5px;
      //     border-left: 1px solid #ddd;
      //     display: flex;
      //     align-items: center;
      //     justify-content: right;

      //     // フォームの幅を固定にしない
      //     .bmd-form-group { width: 100%; }
      //   }
      // }
      // .distribution-item-dept:nth-child(n + 2) {
      //   border-top: 1px solid #ddd;
      // }
    }

    /* 1行目 */
    // .distribution-amount-warning {
    //   grid-column: 2 / 4;
    //   grid-row: 1;
    //   display: flex;
    //   align-items: center;
    //   text-align: center;
    // }


    // .btns-grid {
    //   grid-column: 4 / 5;
    //   grid-row: 1;
    //   padding: 0px;
    //   // ラベルと、値を横並びにするためにflex
    //   display: flex;
    //   justify-content: flex-end;
    //   align-items: center;
    // }
    /* 一行目(終) */
  }

  .serial-numbers-div {
    grid-column: 1  / -1;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    word-break: break-all;
    padding-top: 5px;

    .grid-label {
      width: 20%;
      padding: 0px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      padding: 5px 5px 5px 5px;
    }

    .grid-value {
      width: 80%;
      padding: 0px;

      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .serial-number-field:nth-of-type(4n + 5) {
        padding: 5px 5px 5px 5px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
        display: flex;
        align-items: center;
        background-color: #fff;

        // フォームの幅を固定にしない
        .bmd-form-group { width: 100%; }
      }
      .serial-number-field:not(:nth-of-type(4n + 5)) {
        padding: 5px 5px 5px 5px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
        display: flex;
        align-items: center;
        background-color: #fff;

        // フォームの幅を固定にしない
        .bmd-form-group { width: 100%; }
      }
    }
    
  }


}
