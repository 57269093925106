.swiper {
  // Swiperの表示領域のデフォルト値(設定しない場合、縦に非常に長い領域になる)
  width: calc(100% - 100px); // 100pxは左右のナビゲーションボタンの領域
  min-height: 210px;
  padding-bottom: 40px; // 下に表示されるページネーションの表示と商品が被らないようにページネーションの分をpaddingで確保
  .item-name {
    line-height: 20px;
    // height: 40px;
    // overflow: hidden;
    white-space: normal;
    word-break: break-all;
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
  }
  .item-image-holder {
    text-align: center;
    margin: auto;
    img.item-image {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border: solid 1px silver;
      background-color: #eeeeee;
    }
  }
  .item-category-name {
    line-height: 20px;
    word-break: break-all;
  }
}
.swiper.no-data {
  height: 140px;
  min-height: 140px;
  padding-bottom: 0px;
}
.swiper-button-next, .swiper-button-prev {
  border: 1px solid navy;
  border-radius: 10px;
  top: 70px; // swiperの1/3
  width: 20px;
  height: 20px;
  @include media-breakpoint-up(xl) {
    border-radius: 8px;
    width: 35px;
    height: 35px;
  }
}
.swiper-button-next:after, .swiper-button-prev:after {
  background-image: none;
  content: none;
}
.newer-item:before {
  content:"後継品";
  color: #ffffff;
  background-color: #4caf50;
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: 0.5rem;
  font-weight: 700; //$badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: text-top;
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  @include border-radius($badge-pill-border-radius);
  margin-left: 3px;
}
