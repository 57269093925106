.read-notice {
  background-color: #eee;
}

.unread-notice {
  font-weight: 900;
}

#dropdown-notice {
  width: 400px;

  .notice-msg {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
