.card-signup {
  .card-header {
    .social-line {
      .btn {
        color: $white-color;
      }
    }
  }
  .text-divider {
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: center;
  }
}

.login-page {
  .card-login {
    .card-header {
      margin-top: -40px !important;
      margin-bottom: 20px;
    }

    .social-line {
      padding: 0;
      margin-top: 15px;
    }

    .card-title {
      margin-top: 10px;
      margin-bottom: 10px;
      font-weight: 700;
    }
  }
}

.signup-page {
    .page-header {
        .container{
            padding-top: 20vh;
        }
    }

    .card-signup {
        border-radius: $border-radius-base * 2;
        @include shadow-16dp();
        margin-bottom: 100px;
        padding: 40px 0px;

    }
    .info-horizontal {
        padding: 0px 0px 20px;
    }

    .social {
        .btn {
            margin: 5px;
        }
        h4 {
            margin-top: 20px;
        }
    }
    .footer {
        .container {
            padding: 0;
        }
        .copyright,
        a{
            color: $white-color;
        }
    }
}

.register-page {
  .page-header{
    background-position: top center;
  }
}

@media all and (max-width: 991px) {
  .page-header {
    &.pricing-page,
    &.register-page{
      min-height: fit-content !important;
    }
  }
}
