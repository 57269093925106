
div.c2 {
  background: #fac8c8 !important;
}

// ヘッダー部分（共通）
div.shipping-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-unit-number {
    grid-column: 9 / 10;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
}

// 院内在庫払出
div.shipping-items-area {
  border-bottom: 1px solid #ddd;
  // 商品の情報と、部署ごとの情報を含んだdiv

  // flexでは配置仕切れないのでgridを使う
  div.shipping-item-wrapper {
    border-top: solid 1px #ddd;

    div.shipping-item-div {
      display: grid;
      grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

      /* no, img, maker, name, model_number, product_code */
      padding: 0.5rem 0 0 0;
      font-size: 14px;
      .item-no {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        padding: 20px 5px 0px 0px;
        text-align: center;
      }
      .item-image-holder {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        img.item-image {
          // 画像サイズはgridの幅に合わせる
          width: 4rem;
          height: 4rem;
          object-fit: cover;
        }
      }
      .item-maker-name {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-name {
        grid-column: 4 / 6;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-model-number {
        grid-column: 6 / 7;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-product-code {
        grid-column: 7 / 8;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-spec {
        grid-column: 8 / 9;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-unit-number {
        grid-column: 9 / 10;
        grid-row: 1 / 2;
        padding: 5px 15px 5px 5px;
        word-break: break-all;
        text-align: right;
      }

      .item-stock-amount {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        // ラベルと、値を横並びにするためにflex
        display: flex;
        justify-content: space-between;

        .grid-label {
          padding: 5px 0px 5px 5px;
          color: #666666;
        }
        .grid-value {
          text-align: right;
          padding: 5px 15px 5px 5px;
        }
      }

      .shipping-details-div {
        grid-column: 1 / -1;
        grid-row: 3 / 4;

        padding-left: 3px;
        padding-top: 5px;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .item-shipping-amount {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          // ラベルと、値を横並びにするためにflex
          display: flex;
          word-break: break-all;

          .grid-label {
            width: 60%;
            padding: 5px 0px 5px 5px;
            background-color: #DBE6F0;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            border-left: 1px solid #93B8B6;
            display: flex;
            align-items: center;
          }
          .grid-value {
            width: 40%;
            padding: 5px 15px 5px 5px;
            background-color: #fff;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            display: flex;
            align-items: center;
            justify-content: right;
          }
        }

        .item-stock-amount {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          // ラベルと、値を横並びにするためにflex
          display: flex;
          word-break: break-all;

          .grid-label {
            width: 60%;
            padding: 5px 0px 5px 5px;
            background-color: #DBE6F0;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            display: flex;
            align-items: center;
          }
          .grid-value {
            width: 40%;
            padding: 5px 15px 5px 5px;
            background-color: #fff;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            display: flex;
            align-items: center;
            justify-content: right;
          }
        }

        .shipping-departments {
          grid-column: 1 / -1;
          grid-row: 2 / 3;

          display: flex;
          word-break: break-all;
          padding-top: 5px;

          .shipping-dept-title {
            width: 20%; 
            padding: 5px 0px 5px 5px;
            background-color: #DBE6F0;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            border-left: 1px solid #93B8B6;
            display: flex;
            align-items: center;
          }
          .shipping-depts {
            width: 80%;

            display: grid;
            // grid-template-columns: repeat(3, 1fr);
            grid-template-columns: repeat(1, 1fr);

            // .shipping-item-dept:not(:nth-child(3n)) { border-right: 1px solid #ddd; }

            .shipping-item-dept {
              display: flex;
              word-break: break-all;

              .shipping-item-dept-name {
                width: 35%;
                padding: 5px 0px 5px 5px;
                color: #666666;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                background-color: #f5f5f5;
                display: flex;
                align-items: center;
              }
              .shipping-item-dept-amount {
                width: 15%;
                padding: 5px 15px 5px 5px;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                border-right: 1px solid #ddd;
                border-left: 1px solid #ddd;
                display: flex;
                align-items: center;
                justify-content: right;
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }

  // 詳細
  div.shipping-item-expired-at-wrapper {
    div.shipping-item-div {
      display: grid;
      grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

      .shipping-details-div {
        grid-column: 1 / -1;
        grid-row: 3 / 4;

        padding-left: 3px;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .item-expired-at {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          // ラベルと、値を横並びにするためにflex
          display: flex;
          word-break: break-all;

          .grid-label {
            width: 60%;
            padding: 5px 0px 5px 5px;
            background-color: #D4E6E6;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            border-left: 1px solid #93B8B6;
            display: flex;
            align-items: center;
          }
          .grid-value {
            width: 40%;
            padding: 5px 15px 5px 5px;
            background-color: #fff;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            display: flex;
            align-items: center;
            justify-content: right;
          }
        }

        .item-stock-amount {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          // ラベルと、値を横並びにするためにflex
          display: flex;
          word-break: break-all;

          .grid-label {
            width: 60%;
            padding: 5px 0px 5px 5px;
            background-color: #D4E6E6;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            display: flex;
            align-items: center;
          }
          .grid-value {
            width: 40%;
            padding: 5px 15px 5px 5px;
            background-color: #fff;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            display: flex;
            align-items: center;
            justify-content: right;
          }
        }

        .shipping-departments {
          grid-column: 1 / -1;
          grid-row: 2 / 3;

          display: flex;
          word-break: break-all;
          padding-top: 5px;

          .shipping-dept-title {
            width: 20%; 
            padding: 5px 0px 5px 5px;
            background-color: #D4E6E6;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            border-left: 1px solid #93B8B6;
            display: flex;
            align-items: center;
          }
          .shipping-depts {
            width: 80%;

            display: grid;
            // grid-template-columns: repeat(3, 1fr);
            grid-template-columns: repeat(1, 1fr);

            // .shipping-item-dept:not(:nth-child(3n)) { border-right: 1px solid #ddd; }

            .shipping-item-dept {
              display: flex;
              word-break: break-all;

              .shipping-item-dept-name {
                width: 35%;
                padding: 5px 0px 5px 5px;
                color: #666666;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                background-color: #f5f5f5;
                display: flex;
                align-items: center;
              }
              .shipping-item-dept-amount {
                width: 15%;
                padding: 5px 15px 5px 5px;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                border-right: 1px solid #ddd;
                border-left: 1px solid #ddd;
                display: flex;
                align-items: center;
                justify-content: right;
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

// グループ在庫払出依頼
div.shipping-request-items-area {
  border-bottom: 1px solid #ddd;

  // flexでは配置仕切れないのでgridを使う
  div.shipping-item-wrapper {
    border-top: solid 1px #ddd;

    div.shipping-item-div {
      display: grid;
      grid-template-columns: 2rem 4rem 7rem 1fr 1fr 1fr 1fr 1fr 1fr;

      /* no, img, maker, name, model_number, product_code */
      padding: 0.5rem 0 0.5rem 0;
      font-size: 14px;
      .item-no {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        padding: 20px 5px 0px 0px;
        text-align: center;
      }
      .item-image-holder {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        img.item-image {
          // 画像サイズはgridの幅に合わせる
          width: 4rem;
          height: 4rem;
          object-fit: cover;
        }
      }
      .item-maker-name {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-name {
        grid-column: 4 / 6;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-model-number {
        grid-column: 6 / 7;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-product-code {
        grid-column: 7 / 8;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
        padding: 5px 5px 5px 5px;
      }
      .item-spec {
        grid-column: 8 / 9;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-unit-number {
        grid-column: 9 / 10;
        grid-row: 1 / 2;
        padding: 5px 15px 5px 5px;
        word-break: break-all;
        text-align: right;
      }

      .shipping-details-div {
        grid-column: 1 / -1;
        grid-row: 3 / 4;

        padding-left: 3px;
        padding-top: 5px;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .item-stock-amount {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
          // ラベルと、値を横並びにするためにflex
          display: flex;
          justify-content: space-between;

          .grid-label {
            padding: 5px 0px 5px 5px;
            color: #666666;
          }
          .grid-value {
            text-align: right;
            padding: 5px 15px 5px 5px;
          }
        }
        .item-shipping-amount {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          // ラベルと、値を横並びにするためにflex
          display: flex;
          word-break: break-all;

          .grid-label {
            width: 60%;
            padding: 5px 0px 5px 5px;
            background-color: #DBE6F0;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            border-left: 1px solid #93B8B6;
            display: flex;
            align-items: center;
          }
          .grid-value {
            width: 40%;
            padding: 5px 15px 5px 5px;
            background-color: #fff;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            display: flex;
            align-items: center;
            justify-content: right;
          }
        }

        .shipping-departments {
          grid-column: 1 / -1;
          grid-row: 2 / 3;

          display: flex;
          word-break: break-all;
          padding-top: 5px;

          .shipping-dept-title {
            width: 20%; 
            padding: 5px 0px 5px 5px;
            background-color: #DBE6F0;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            border-left: 1px solid #93B8B6;
            display: flex;
            align-items: center;
          }
          .shipping-depts {
            width: 80%;

            display: grid;
            // grid-template-columns: repeat(2, 1fr);
            grid-template-columns: repeat(1, 1fr);

            // .shipping-item-dept:not(:nth-child(2n)) { border-right: 1px solid #ddd; }

            .shipping-item-dept {
              display: flex;
              word-break: break-all;

              .shipping-item-dept-name {
                width: 35%;
                padding: 5px 0px 5px 5px;
                color: #666666;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                background-color: #f5f5f5;
                display: flex;
                align-items: center;
              }
              .shipping-item-dept-amount {
                width: 15%;
                padding: 5px 5px 5px 5px;
                border-top: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                border-left: 1px solid #ddd;
                border-right: 1px solid #ddd;
                display: flex;
                align-items: center;
                justify-content: right;
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

// グループ在庫払出作成確認画面
div.shipping-group-items-area {
  border-bottom: 1px solid #ddd;

  // flexでは配置仕切れないのでgridを使う
  div.shipping-item-wrapper {
    border-top: solid 1px #ddd;

    div.shipping-item-div {
      display: grid;
      grid-template-columns: 2rem 4rem 7rem 1fr 1fr 1fr 1fr 1fr 1fr;

      /* no, img, maker, name, model_number, product_code */
      padding: 0.5rem 0 0.5rem 0;
      font-size: 14px;
      .item-no {
        grid-column: 1 / 2;
        grid-row: 1 / 3;
        padding: 20px 5px 0px 0px;
        text-align: center;
      }
      .item-image-holder {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        img.item-image {
          // 画像サイズはgridの幅に合わせる
          width: 4rem;
          height: 4rem;
          object-fit: cover;
        }
      }
      .item-maker-name {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-name {
        grid-column: 4 / 6;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-model-number {
        grid-column: 6 / 7;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-product-code {
        grid-column: 7 / 8;
        grid-row: 1 / 2;
        padding: 5px 5px 5px 5px;
        word-break: break-all;
      }
      .item-spec {
        grid-column: 8 / 9;
        grid-row: 1 / 2;
        word-break: break-all;
        padding: 5px 5px 5px 5px;
      }
      .item-unit-number {
        grid-column: 9 / 10;
        grid-row: 1 / 2;
        padding: 5px 15px 5px 5px;
        word-break: break-all;
        text-align: right;
      }

      .shipping-details-div {
        grid-column: 1 / -1;
        grid-row: 3 / 4;

        padding-left: 3px;
        padding-top: 5px;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .item-stock-amount {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          // ラベルと、値を横並びにするためにflex
          display: flex;
          word-break: break-all;

          .grid-label {
            width: 60%;
            padding: 5px 0px 5px 5px;
            background-color: #DBE6F0;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            border-left: 1px solid #93B8B6;
            display: flex;
            align-items: center;
          }
          .grid-value {
            width: 40%;
            padding: 5px 15px 5px 5px;
            background-color: #fff;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            display: flex;
            align-items: center;
            justify-content: right;
          }
        }
        .item-shipping-amount {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
          // ラベルと、値を横並びにするためにflex
          display: flex;
          word-break: break-all;

          .grid-label {
            width: 60%;
            padding: 5px 0px 5px 5px;
            background-color: #DBE6F0;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            display: flex;
            align-items: center;
          }
          .grid-value {
            width: 40%;
            padding: 5px 15px 5px 5px;
            background-color: #fff;
            border-top: 1px solid #93B8B6;
            border-bottom: 1px solid #93B8B6;
            border-right: 1px solid #93B8B6;
            display: flex;
            align-items: center;
            justify-content: right;
          }
        }
      }
    }
  }
}

div.shipping-supp-appl-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 3rem 1fr 7rem 8rem 1fr 1fr 1fr;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .no {
    grid-column: 1/ 2;
    padding: 5px;
  }
  .user-name {
    grid-column: 2 / 3;
    padding: 5px;
  }
  .amount {
    grid-column: 3 / 4;
    padding: 5px;
  }
  .applied-at {
    grid-column: 4 / 5;
    padding: 5px;
  }
  .note {
    grid-column: 5 / -1;
    padding: 5px;
  }
}

div.shipping-supp-appl-item-div {
  display: grid;
  grid-template-columns: 3rem 1fr 7rem 8rem 1fr 1fr 1fr;

  margin: 2px 0px;
  font-size: 14px;
  border-bottom: 1px solid #93B8B6;

  .no {
    grid-column: 1 / 2;
    grid-row: 1;
    padding: 5px;
    text-align: center;
  }
  .user-name {
    grid-column: 2 / 3;
    padding: 5px;
    word-break: break-all;
  }
  .amount {
    grid-column: 3 / 4;
    grid-row: 1;
    padding: 5px 30px 5px 5px;
    text-align: right;
    word-break: break-all;
  }
  .applied-at {
    grid-column: 4 / 5;
    grid-row: 1;
    padding: 5px;
    word-break: break-all;
  }
  .note {
    grid-column: 5 / -1;
    grid-row: 1;
    padding: 5px;
    word-break: break-all;
  }

  .supp-appl-patients-title {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .supp-appl-patients-wrapper {
    grid-column: 1 / -1;
    grid-row: 3;

    div.supp-appl-patients-div {
      .supp-appl-patients-header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 14rem;

        .patient-user-name {
          grid-column: 1 / 2;
          padding: 0 5px 0 5px;
          background-color: #DBE6F0;
          border-top: 1px solid #93B8B6;
          border-bottom: 1px solid #93B8B6;
          border-left: 1px solid #93B8B6;
          border-right: 1px solid #ddd;
        }
        .patient-id {
          grid-column: 2 / 3;
          padding: 0 5px 0 5px;
          background-color: #DBE6F0;
          border-top: 1px solid #93B8B6;
          border-bottom: 1px solid #93B8B6;
          border-right: 1px solid #ddd;
        }
        .patient-amount {
          grid-column: 3 / 4;
          padding: 0 5px 0 5px;
          background-color: #DBE6F0;
          border-top: 1px solid #93B8B6;
          border-bottom: 1px solid #93B8B6;
          border-right: 1px solid #ddd;
        }
        .patient-term {
          grid-column: 4 / 5;
          padding: 0 5px 0 5px;
          background-color: #DBE6F0;
          border-top: 1px solid #93B8B6;
          border-bottom: 1px solid #93B8B6;
          border-right: 1px solid #93B8B6;
        }
      }
  
      .supp-appl-patients {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 14rem;
  
        .patient-user-name {
          grid-column: 1 / 2;
          padding: 5px 15px 5px 5px;
          border-bottom: 1px solid #ddd;
          border-left: 1px solid #93B8B6;
          border-right: 1px solid #ddd;
          display: flex;
          align-items: center;
        }
        .patient-id {
          grid-column: 2 / 3;
          padding: 5px 25px 5px 5px;
          border-bottom: 1px solid #ddd;
          border-right: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        .patient-amount {
          grid-column: 3 / 4;
          padding: 5px 35px 5px 5px;
          border-bottom: 1px solid #ddd;
          border-right: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: right;
        }
        .patient-term {
          grid-column: 4 / 5;
          padding: 5px 15px 5px 5px;
          border-bottom: 1px solid #ddd;
          border-right: 1px solid #93B8B6;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
