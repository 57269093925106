.card-product{
    margin-top: 30px;

    .btn-simple.btn-just-icon{
        padding: 0;
    }

    .card-footer{
        .price{
            h4{
                margin-bottom: 0;
            }
        }
        .btn{
            margin: 0;
        }
    }

    .card-title,
    .card-category,
    .card-description{
        text-align: center;
    }
    .category{
        margin-bottom: 0;
    }
    .category ~ .card-title{
        margin-top: 0;
    }

    .price{
        font-size: 18px;
        color: #9A9A9A;
    }
    .price-old{
        text-decoration: line-through;
        font-size: 16px;
        color: #9A9A9A;
    }
    .price-new{
        color: $danger;
    }
}
