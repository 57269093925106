// Bootstrap brand color customization


/*     brand Colors     */

$primary:           $purple-500 !default;
$secondary:         $grey-600 !default;
$success:           $green-500 !default;
$info:              $cyan-500 !default;
$warning:           $orange-500 !default;
$rose:              $pink-500 !default;
$danger:            $red-500 !default;
$light:             $grey-100 !default;
$dark:              $grey-800 !default;
$inverse:           $black-color !default;


$theme-card-colors: () !default;
$theme-card-colors: map-merge((
  "primary":    ($purple-400, $purple-600),
  "success":    ($green-400, $green-600),
  "info":       ($cyan-400, $cyan-600),
  "warning":    ($orange-400, $orange-600),
  "danger":     ($red-400, $red-600),
  "rose":       ($pink-400, $pink-600),
  "bs-primary": ($blue, $blue),
  "brown":      ($dull-rose, $dull-rose),
  "orange":     ($new-orange, $new-orange),
  "gold":       ($gold, $gold),
  "green":      ($new-green, $new-green),
  "lime":       ($new-lime, $new-lime),
  "cyan":       ($new-cyan, $new-cyan),
  "navy":       ($navy, $navy),
  "dull-gold":  ($dull-gold, $dull-gold),
  "turquoise":  ($turquoise, $turquoise)
), $theme-card-colors);

$theme-shadow-colors: () !default;
$theme-shadow-colors: map-merge((
  "primary":    $purple-400,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "rose":       $rose,
  "bs-primary": $blue,
  "brown":      $dull-rose,
  "orange":     $new-orange,
  "gold":       $gold,
  "green":      $new-green,
  "lime":       $new-lime,
  "cyan":       $new-cyan,
  "navy":       $navy,
  "dull-gold":  $dull-gold,
  "turquoise":  $turquoise
), $theme-shadow-colors);
