div.inventory-details-div {
  div.inventory-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    font-size: 14px;

    div.inventory-detail {
      display: grid;
      grid-template-columns: 1fr;

      // 一段目(ヘッダー)
      .header-row {
        background-color: #f5f5f5;

        grid-column: 1 / -1;
        grid-row: 1 / 2;

        display: grid;
        grid-template-columns: 10rem repeat(3, 1fr) 3rem;
        .header-expired-at {
          grid-column: 1 / 2;
        }
        .header-unit-amount {
          grid-column: 2 / 3;
        }
        .header-atomic-amount {
          grid-column: 3 / 4;
        }
        .header-exist-amount {
          grid-column: 4 / 5;
        }
      }
        
      // 二段目（フォーム）
      .form-row {
        grid-column: 1 / -1;
        grid-row: 2 / 3;

        display: grid;
        grid-template-columns: 10rem repeat(3, 1fr) 3rem;
        .inventory-details-expired-at-name {
          grid-column: 1 / 2;
        }
        .inventory-details-unit-amount {
          grid-column: 2 / 3;
        }
        .inventory-details-atomic-amount {
          grid-column: 3 / 4;
        }
        .inventory-details-exist-amount {
          grid-column: 4 / 5;
        }
        .inventory-details-remove-btn {
          .reject-expired-at {
            cursor: default;
            margin-top: 10px;
            color: red;
          }
        }
      }
    }
  }
}