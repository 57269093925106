// 仕入先別勘定科目（分析画面）

@mixin staickTableTdBorder($borderArray, $color) {
  @each $value in $borderArray {
    border-#{$value}: 1px solid $color;
  }
}

$th-border-color: #93B8B6;
$td-border-color: #ddd;

div#account-by-supplier-table {
  margin-top: 20px;
  width: 100%;
  overflow: scroll;
  height: calc(100vh - 230px);

  table {
    table-layout: fixed;
    width: auto;
    max-width: none;
    border-collapse: separate;
    border-spacing: 0;

    tr {
      th {
        font-size: 12px;
        text-align: center;
        background-color: #DBE6F0;
        padding: 5px 5px;
      }
      th.no {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 2;
        width: 40px;
        min-width: 40px;
        $arr: 'top', 'left', 'bottom', 'right';
        @include staickTableTdBorder($arr, $th-border-color);
      }
      th.supplier {
        position: sticky;
        top: 0;
        left: 40px;
        z-index: 2;
        width: 140px;
        min-width: 140px;
        $arr: 'top', 'bottom', 'right';
        @include staickTableTdBorder($arr, $th-border-color);
      }
      th.all-category {
        position: sticky;
        top: 0;
        z-index: 1;
        text-align: left;
        $arr: 'top', 'bottom', 'right';
        @include staickTableTdBorder($arr, $th-border-color);
      }
      th.category {
        position: sticky;
        top: 36px;
        z-index: 1;
        width: 100px;
        min-width: 100px;
        $arr: 'bottom', 'right';
        @include staickTableTdBorder($arr, $th-border-color);
      }
      th.supplier-sum {
        position: sticky;
        top: 0;
        z-index: 2;
        width: 140px;
        min-width: 140px;
        $arr: 'top', 'bottom', 'right';
        @include staickTableTdBorder($arr, $th-border-color);
      }
      td {
        font-size: 12px;
        background: #fff;
        border-top: 0;
        padding: 5px 5px;
      }
      td.no {
        position: sticky;
        left: 0;
        background-color: #fff;
        z-index: 1;
        text-align: right;
        $arr: 'left', 'bottom', 'right';
        @include staickTableTdBorder($arr, $td-border-color);
      }
      td.supplier {
        position: sticky;
        left: 40px;
        background-color: #fff;
        z-index: 1;
        text-align: left;
        $arr: 'bottom', 'right';
        @include staickTableTdBorder($arr, $td-border-color);
      }
      td.category {
        $arr: 'bottom', 'right';
        text-align: right;
        @include staickTableTdBorder($arr, $td-border-color);
      }
      td.sum {
        // background-color: #DBE6F0;
        text-align: center;
        $arr: 'left', 'bottom', 'right';
        @include staickTableTdBorder($arr, $td-border-color);
      }
    }
  }
}
