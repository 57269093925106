div.purc-appl-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
}

div.purc-appl-btn-header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 50;
  height: 34px;
  display: flex;
  justify-content: flex-end;
  z-index: 1001;
}

// 削除ボタンにstickyを使う場合はその分の高さだけtopを指定する
// .form-headerを追加で設定しておく
div.purc-appl-header.form-header {
  top: 34px;
  z-index: 1001;
}

// 商品の情報と、部署ごとの情報を含んだdiv
div.purc-appl-item-wrapper {
  padding-bottom: 10px;
  border-bottom: solid 1px #ddd;
  .purc-appl-rejected-item {
    background-color: lightgray;
  }
}

div.purc-appl-item-wrapper.search-target {
  background-color: rgba(255, 255, 0, 0.2);
  .purc-appl-rejected-item {
    background-color: #BDB76B;
  }
  // .application-rejected-item-dept {
  //   background-color: #BDB76B;
  // }
}

// flexでは配置しきれないのでgridを使う
div.purc-appl-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;

  /* no, img, maker, name, model_number, product_code */
  padding: 0.5rem 0 0.5rem 0;
  // margin: 2px 0px;
  font-size: 14px;
  .item-no {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
}

.purc-appl-details-div {
  grid-column: 1 / -1;
  grid-row: 2 / 4;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media only screen and (max-width: 1180px) {
    grid-template-columns: 1fr 1fr;
  }

  .item-regular-price {
    grid-column: 1 / 2;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;
    word-break: break-all;

    .grid-label {
      width: 30%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      text-align: right;
      padding: 5px 15px 5px 5px;
      width: 70%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

  .item-redemption-price {
    grid-column: 2 / 3;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;
    word-break: break-all;

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      text-align: right;
      padding: 5px 15px 5px 5px;
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
      @media only screen and (max-width: 1180px) {
        border-right: 1px solid #93B8B6;
      }
    }
  }

  .item-unit-number {
    grid-column: 3 / 4;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;
    word-break: break-all;
    @media only screen and (max-width: 1180px) {
      grid-column: 1 / 2;
    }

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1180px) {
        width: 30%;
        border-top: none;
      }
    }

    .grid-value {
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
      @media only screen and (max-width: 1180px) {
        width: 70%;
        border-top: none;
      }
    }
  }

  .application-item-amount {
    grid-column: 4 / 5;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;
    word-break: break-all;
    @media only screen and (max-width: 1180px) {
      grid-column: 2 / 3;
    }

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1180px) {
        border-top: none;
      }
    }

    .grid-value {
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
      @media only screen and (max-width: 1180px) {
        border-top: none;
      }
    }
  }

  .application-item-supplier {
    grid-column: 1 / 2;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    word-break: break-all;

    .grid-label {
      width: 30%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 70%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }

    // フォームがある時
    .application-item-supplier-value {
      width: 70%;
      padding: 5px 5px 5px 5px;
      background-color: #fff;
      border-bottom: 1px solid #93B8B6;
      // セレクトボックスなのでdisplay: flexを無効にする
      // display: flex;
      // align-items: center;
    }
  }
  .application-item-supplier-empty {
    grid-column: 1 / 2;
    // ラベルと、値を横並びにするためにflex
    display: flex;

    .grid-label {
      width: 30%;
      border-left: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
    }

    .grid-value {
      width: 70%;
      border-bottom: 1px solid #93B8B6;
    }
  }

  .item-unit-pirce-change-date {
    grid-column: 2 / 3;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-bottom: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1180px) {
        border-right: 1px solid #93B8B6;
      }
    }
  }

  .application-item-unit-pirce {
    grid-column: 3 / 4;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;
    word-break: break-all;
    @media only screen and (max-width: 1180px) {
      grid-column: 1 / 2;
    }

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-bottom: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1180px) {
        width: 30%;
      }
    }

    .grid-value {
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
      @media only screen and (max-width: 1180px) {
        width: 70%;
      }
    }

    // フォームがある時
    .application-item-unit-price-value {
      width: 60%;
      padding: 5px 5px 5px 5px;
      background-color: #fff;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
      @media only screen and (max-width: 1180px) {
        width: 70%;
      }
    }
  }

  .application-item-total-pirce {
    grid-column: 4 / 5;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;
    word-break: break-all;
    @media only screen and (max-width: 1180px) {
      grid-column: 2 / 3;
    }

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-bottom: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

  .application-departments {
    padding-top: 10px;
    grid-column: 1 / -1;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    word-break: break-all;

    .grid-label {
      width: 25%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 75%;
      padding: 0px;

      display: grid;
      grid-template-columns: repeat(1, 1fr);

      .application-item-dept {
        display: flex;
        word-break: break-all;
        // 数量ゼロなら非表示
        &.no-amount {
          display: none;
        }

        .application-item-dept-name {
          width: 33.3%;
          padding: 5px 0px 5px 5px;
          color: #666666;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          background-color: #f5f5f5;
          display: flex;
          align-items: center;
        }
        .application-item-dept-amount {
          width: 25%;
          padding: 5px 5px 5px 5px;
          border-top: 1px solid #ddd;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: right;
          background-color: #fff;
        }
        // フォームがある時(差戻再編集時)
        .application-item-dept-amount-value {
          width: 25%;
          padding: 5px 5px 5px 5px;
          border-top: 1px solid #ddd;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: right;
          background-color: #fff;
        }

        // 部署ごとの一部却下用デザイン
        &.application-rejected-item-dept {
          .application-item-dept-amount {
            background-color: lightgray;
          }
        }
      }
      // .application-item-dept:not(:nth-child(3n)) {
      //   .application-item-dept-amount { border-right: 1px solid #ddd; }
      //   .application-item-dept-amount-value { border-right: 1px solid #ddd; }
      // }
      // .application-item-dept:nth-child(n + 4) {
      //   .application-item-dept-name { border-top: 1px solid #ddd; }
      //   .application-item-dept-amount { border-top: 1px solid #ddd; }
      //   .application-item-dept-amount-value { border-top: 1px solid #ddd; }
      // }
    }
  }

}

  // .purc-appl-details-div {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr 1fr 1fr;

  // }

  // .application-dept-title {
  //   grid-column: 1 / 3;
  //   grid-row: 4 / 5;
  //   padding: 5px 5px 5px 5px;
  //   word-break: break-all;
  // }


// 商品全体却下のデザイン
.purc-appl-item-div.purc-appl-rejected-item {
  .purc-appl-details-div {
    .application-item-amount,
    .application-item-total-pirce {
      .grid-value {
        background-color: lightgray;
      }
    }
  }
}

div.purc-appl-item-total-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 14px;
  word-break: break-all;

  .purc-appl-total-price {
    grid-column: 4 / 5;
    display: flex;

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }

}
