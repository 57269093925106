
div.supp-appr-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 2rem 4rem 1fr 3fr 1fr 1fr 1fr 9.5rem;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column-start: 1;
    padding: 5px 5px 5px 5px;
  }
  .item-reject {
    grid-column-start: 2;
    grid-column-end: 4;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column-start: 4;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column-start: 5;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column-start: 6;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column-start: 7;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column-start: 8;
    padding: 5px 5px 5px 5px;
  }
  .application-amount {
    grid-column-start: 9;
    padding: 5px 5px 5px 5px;
  }
  .application-note {
    display: none;
  }
}

// 商品の情報入力欄と、患者様入力欄を含んだdiv
div.supp-appr-item-wrapper {
  border-bottom: solid 1px #ddd;
}

// flexでは配置しきれないのでgridを使う
div.supp-appr-item-div {
  display: grid;
  grid-template-columns: 2rem 2rem 4rem 1fr 3fr 1fr 1fr 1fr 9.5rem;
  padding: 0.5rem 0 0.5rem 0;
  font-size: 14px;
  .item-no {
    grid-column-start: 1;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-reject {
    grid-column-start: 2;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column-start: 3;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column-start: 4;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column-start: 5;
    padding: 5px 5px 5px 5px;
    //min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    //max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column-start: 6;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column-start: 7;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column-start: 8;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .approve-amount { // 減数
    grid-column-start: 9;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 0px 0px;
    input {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    // dropdownがでかすぎるのを調整
    button {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-color: #ced4da;
      box-shadow: none;
      padding: 7px !important;
      //padding-left: 0.4rem !important;
      //padding-right: 0.4rem !important;
    }
    .dropdown-menu {
      min-width: 2rem !important;
      .dropdown-item {
        text-align: right;
        padding: 5px;
        min-width: 2rem !important;
        justify-content: center;
      }
    }
  }
  .application-note {
    grid-column-start: 4;
    grid-column-end: 8;
    grid-row-start: 2;
    padding: 0 5px;
  }
}

.supp-appr-item-wrapper {
  .patient-header {
    color: #666666;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  @media screen and (min-width: 576px) {
    // 左右の患者様情報の間に区切り線をつける
    .patient-header:nth-child(2n + 1),.patient-field-row:nth-child(2n + 1) {
      border-right: 1px solid #ddd;
    }
    // 上下の患者様情報の間に区切り線をつける
    .patient-field-row:nth-child(n + 3) {
      border-top: 1px solid #ddd;
    }
  }

  @media screen and (max-width: 575px) {
    .patient-field-row:nth-child(n + 2) {
      border-top: 1px solid #ddd;
    }
  }
}

