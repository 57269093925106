div.return-item-header {
  display: grid;
  grid-template-columns: 2rem 4rem repeat(6, 1fr);
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
}

// 商品の情報と、部署ごとの情報を含んだdiv
div.return-item-item-wrapper {
  border-bottom: solid 1px #ddd;
  .return-item-rejected-item {
    background-color: lightgray;
  }
}

// flexでは配置しきれないのでgridを使う
div.return-item-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem repeat(6, 1fr);

  /* no, img, maker, name, model_number, product_code, spec */
  padding: 0.5rem 0 0.5rem 0;
  margin: 2px 0px;
  font-size: 14px;
  .item-no {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }

  .grid-label {
    padding: 5px 5px 5px 5px;
    color: #666666;
  }

  .grid-value {
    padding: 5px 5px 5px 5px;
  }

  .return-item-amount {
    grid-column: 8 / 9;
    grid-row: 2 / 3;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;

    .grid-label {
      padding: 5px 0px 5px 5px;
      color: #666666;
    }

    .grid-value {
      text-align: right;
      padding: 5px 15px 5px 5px;
    }
  }

  .return-item-supplier-box {
    grid-column: 3 / 7;
    grid-row: 2 / 3;

    .return-item-supplier-grid {
      display: grid;
      grid-template-columns: 6.5rem 1fr 6rem 8rem;
      .return-item-supplier {
        grid-column: 1 / 3;
        // ラベルと、値を横並びにするためにflex
        display: flex;
        justify-content: space-between;
        .return-item-supplier-value {
          width: calc(100% - 6rem);
        }
        :after {
          margin-left: 0px;
        }
        &.confirm {
          .grid-value {
            margin-left: 1rem;
          }
          justify-content: normal;
        }
      }
      .return-item-order-number {
        grid-column: 3 / 5;
        // ラベルと、値を横並びにするためにflex
        display: flex;
        justify-content: space-between;
        .grid-label {
          padding: 5px 5px 5px 15px;
          color: #666666;
        }
        .return-item-order-number-value {
          width: 8rem;
        }
        &.confirm {
          justify-content: normal;
        }
      }
    }
  }

  .return-item-total-amount {
    grid-column: 7 / 8;
    grid-row: 2 / 3;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;
  }

  .return-item-item-select-button {
    grid-column: 8 / 9;
    grid-row: 2 / 3;
  }

  .return-item-blank-message {
    grid-column: 1 / 8;
    grid-row: 2 / 3;
  }
}

div.return-item-stock-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  font-size: 14px;
  border-bottom: solid 1px #ddd;
  padding: 0.5rem 0 0.5rem 2rem;

  .return-item-stock-title {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  // 部署名は1列使用する
  .return-item-dept-name {
    grid-column: 1 / -1;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    padding: 5px 0px 5px 5px;
    margin-bottom: 5px;
    font-weight: bold;
    .dept-name-grid {
      display: grid;
      grid-template-columns: 4rem repeat(6, 1fr);
      .name {
        grid-column: 1 / -2;
        display: flex;
      }
      .blank {
        grid-column: 1 / -2;
        font-weight: normal;
        margin-left: -2rem;
        .grid-label {
          padding: 5px 5px 5px 5px;
          color: #666666;
        }
      }
      .btn {
        grid-column: -2 / -1;
        margin: 0;
      }
    }
  }

  .return-item-stock {
    // 部署の中身グリッドにする
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 5px 5px 5px 5px;

    .grid-label {
      padding: 5px 5px 5px 5px;
      color: #666666;
    }
    .grid-value {
      padding: 5px 5px 5px 5px;
    }

    .return-item-expired-at {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      display: flex;
      justify-content: space-between;
    }
    .return-item-stock-amount {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      display: flex;
      justify-content: space-between;
    }
    .return-item-amount {
      grid-column: 3 / -1;
      grid-row: 1 / 2;
      display: flex;
      justify-content: space-between;

      .return-item-amount-value {
        width: 100%;
        max-width: 6rem;
      }
    }
  }
}

div.return-item-bottom {
  display: grid;
  grid-template-columns: 2rem 4rem repeat(6, 1fr);
  font-size: 14px;

  .grid-label {
    padding: 5px 5px 5px 5px;
    color: #666666;
  }

  .grid-value {
    padding: 5px 5px 5px 5px;
  }

  .return-item-return-price {
    grid-column: -3 / -1;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: space-between;
    .return-item-return-price-value {
      width: 100%;
      max-width: 9.5rem;
    }
    .grid-label:after {
      margin-left: 0px;
    }
  }
}
