// なぜかvendor/fontawesomeの中のscssだとwebfontが読み出せないのでここで定義

$fa-font-path : '../vendor/fontawesome-pro-6.0.0-beta1/webfonts';

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-thin-100.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-thin-100.woff') format('woff'),
    url('#{$fa-font-path}/fa-thin-100.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-solid-900.woff') format('woff'),
    url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-duotone-900.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-duotone-900.woff') format('woff'),
    url('#{$fa-font-path}/fa-duotone-900.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-brands-400.woff') format('woff'),
    url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-light-300.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-light-300.woff') format('woff'),
    url('#{$fa-font-path}/fa-light-300.ttf') format('truetype');
}

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: $fa-font-display;
  src: url('#{$fa-font-path}/fa-regular-400.woff2') format('woff2'),
    url('#{$fa-font-path}/fa-regular-400.woff') format('woff'),
    url('#{$fa-font-path}/fa-regular-400.ttf') format('truetype');
}
