div.bsbsa-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 3fr 1fr 1fr 2fr 5rem 5rem;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  padding: 0.5rem 0 0.5rem 0;
  .item-no {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    padding: 5px 5px 5px 5px;
  }
  .item-unit-number {
    padding: 5px 5px 5px 5px;
  }
  .application-amount {
    padding: 5px 5px 5px 5px;
  }
}

// flexでは配置しきれないのでgridを使う

div.bsbsa-item-div:not(:last-child) {
  padding: 0.5rem 0 0.5rem 0;
}

div.bsbsa-item-div:last-child {
  padding: 0.5rem 0 0 0;
}

div.bsbsa-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 3fr 1fr 1fr 2fr 5rem 5rem;
  /* no, img, maker, name, model_number, product_code, spec, unit_number */
  font-size: 14px;
  .item-no {
    grid-column-start: 1;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column-start: 2;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column-start: 3;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column-start: 4;
    padding: 5px 5px 5px 5px;
    //min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    //max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column-start: 5;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column-start: 6;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column-start: 7;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-unit-number {
    grid-column-start: 8;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .application-amount {
    grid-column: 9 / 10;
    grid-row: 1 / 2;
  }
  .operation-area {
    grid-column: 9 / 10;
    grid-row: 2 / 3;
  }
  .error-text-area {
    grid-column: 4 / 8;
    grid-row: 2 / 3;
  }

}