.stock-list-area {
  width: 100%;

  display: grid;
  grid-template-columns: 6rem 1fr 1fr;

  .stock-list-title {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .stock-list-details {
    grid-column: 2 / -1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .detail {
      display: flex;
      align-items: center;
      padding: 0.4rem;
      flex-wrap: nowrap;
    }
  }
}

table#stocks {
  tbody {
    tr.dt-hasChild {
      td{
        border-bottom: 1px dashed #efecec;
      }
    }
    tr.isChild:not(:first-child) td{
      border-top: none;
      border-bottom: none;
    }
    td.ellipsis div {
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
}
