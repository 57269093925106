#item-suppl-appl-alert {
  position: fixed;
  width: 600px;
  max-width: 85vw;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 2;
}

// checkboxのどちらにcheckが入っているかでgrid/listの表示を分ける
// 各columnごとのgrid/grid-image/grid-small/listでのdisplay値
$item-all-cols: (
  no:               (none, none, none, block),
  maker-name:       (block, block, block, block),
  name:             (block, block, block, block),
  model-number:     (block, block, none, block),
  product-code:     (block, block, none, block),
  spec:             (block, block, none, block),
  use-unit:         (block, block, none, block),
  regular-price:    (block, block, none, none),
  redemption-price: (block, block, none, none),
  category-name:    (none, none, none, none), // 現場に見せなくなったのですべてnone
  display-status:   (none,  none, none,  none),
  spd-status:       (block, block, block, none),
  patient-required:  (none, none, none, block),
  last-date:        (block, block, none, none),
  buttons:          (block, none, none, none),
);

label.label-for-grid, label.label-for-grid-image, label.label-for-grid-small, label.label-for-list {
  color: theme-color("secondary");
}

div.list-header {
  font-size: 12px;
  .item-no {
    padding: 5px 5px 5px 5px;
    width: 3rem;
    min-width: 3rem;
    max-width: 3rem;
  }
  .item-buttons {
    padding: 5px 5px 5px 5px;
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
  }
  .item-maker-name {
    padding: 5px 5px 5px 5px;
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
  }
  .item-name {
    padding: 5px 5px 5px 5px;
    flex-grow: 1;
  }
  .item-model-number {
    padding: 5px 5px 5px 5px;
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
  }
  .item-product-code {
    padding: 5px 5px 5px 5px;
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
  }
  .item-spec {
    padding: 5px 5px 5px 5px;
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
  }
  .item-use-unit {
    padding: 5px 5px 5px 5px;
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
  }
  .item-category-name {
    padding: 5px 5px 5px 5px;
    width: 6rem;
    min-width: 6rem;
    max-width: 6rem;
  }
  .item-patient-required {
    padding: 5px 5px 5px 5px;
    width: 6rem;
    min-width: 6rem;
    max-width: 6rem;
  }
}

.grid-or-list {
  cursor: pointer;
  // あふれたら必ず...で切る
  @each $c, $a in $item-all-cols {
    .item-#{$c} {
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
}

// grid表示用のstyle
$item-width: 280px;
#grid:checked {
  & ~ div.label-field {
    label.label-for-grid {
      color: blue;
      //color: theme-color(bs-primary);
    }
  }
  & ~ div.list-header { // ヘッダ非表示
    display: none;
  }

  // 画像無し表示で選択されていることがわかるようにする
  & ~ div.d-flex .grid-or-list:has(input:checked) {
    background-color: lightgray;
    .item-name {
      background-color: gray;
    }
  }

  & ~ div.d-flex .grid-or-list {
    display: grid; // orderを機能させるためにgridにする
    border: 1px solid silver;
    padding: 5px 5px 5px 5px;
    margin: 0.5rem 0 1rem 0;
    line-height: 1rem;
    width: $item-width + 10px;
    cursor: default;

    .item-image-holder { // 画像を非表示にする
      display: none;
    }
    // gridの場合ラベルの文字色をやや薄くする
    span.item-list-label {
      color: #666;
      padding-right: 0.5rem;
    }
    // $aの1番目がgrid用
    @each $c, $a in $item-all-cols {
      .item-#{$c} {
        display: nth($a, 1);
        max-width: $item-width;
      }
    } // @each
    // 商品名のみ２行で出す
    .item-name {
      order: 0;
      font-size: 18px; // 文字を大きくする(他は14pxが基本)
      line-height: 20px; // 少し狭めに
      height: 40px;
      max-height: 40px;
      overflow: hidden;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      .item-list-label {
        display: none;
      }
      background-color: #EEF;
      padding: 2px 2px 2px 2px;
    }
    .item-spec {
      order: 1;
    }
    .item-model-number {
      order: 2;
    }
    .item-product-code {
      order: 3;
    }
    .item-maker-name {
      order: 4;
    }
    .item-use-unit {
      order: 5;
    }
    // 定価と償還価格を一行にまとめる
    .item-regular-price {
      order: 6;
      display: block;
      margin-bottom: -2px;
      //width: 40%;
    }
    .item-redemption-price {
      order: 6;
      display: block;
      margin-bottom: -2px;
    }
    .item-spd-status {
      order: 7;
    }
    .item-patient-required {
      order: 8;
    }
    .item-last-date {
      order: 9;
    }
    .item-buttons {
      order: 10;
      overflow: visible;
      .item-amount-input {
        width: 5rem;
      }
      .item-amount-unit {
        display: inline-block;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        vertical-align: middle;
      }
    }
  }
  & ~ div.d-flex .grid-or-list-dummy { // 幅だけは正規で高さが1pxのデータを作っておく
    display: block;
    width: $item-width + 10px;
    min-width: $item-width + 10px;
    max-width: $item-width + 10px;
    min-height: 1px;
    max-height: 1px;
  }
}

// grid-image表示用のstyle
$item-width: 280px;
#grid-image:checked {
  & ~ div.label-field {
    label.label-for-grid-image {
      color: blue;
      //color: theme-color(bs-primary);
    }
  }
  & ~ div.list-header { // ヘッダ非表示
    display: none;
  }

  & ~ div.d-flex .grid-or-list {
    display: block;
    padding: 5px 5px 5px 5px;
    line-height: 1rem;
    width: $item-width + 10px; // 画像によっては変な隙間が空くのでwidthを指定する

    .item-image-holder {
      display: block;
      position: relative;
      width: $item-width;
      height: $item-width;
      .item-image {
        display: inline;
        width: $item-width;
        height: $item-width;
        object-fit: cover;
        //&:hover {
        //  object-fit: cover;
        //}
      }
      .grid-favorite-star {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 28px;
      }
      .grid-check-div {
        position: absolute;
        top: 0px;
        right: 10px;
        font-size: 12px;
        .form-check-label {
          padding-left: 0px;
        }
      }
    }
    // gridの場合ラベルの文字色をやや薄くする
    span.item-list-label {
      color: #666;
      padding-right: 0.5rem;
    }
    // $aの2番目がgrid-image用
    @each $c, $a in $item-all-cols {
      .item-#{$c} {
        display: nth($a, 2);
        max-width: $item-width;
      }
    } // @each
    // 商品名のみ２行で出す
    .item-name {
      line-height: 20px; // 少し狭めに
      height: 40px;
      max-height: 40px;
      overflow: hidden;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    // 定価と償還価格を一行にまとめる
    .item-regular-price {
      display: inline-block;
      margin-bottom: -2px;
      width: 40%;
    }
    .item-redemption-price {
      display: inline-block;
      margin-bottom: -2px;
    }
  }
  & ~ div.d-flex .grid-or-list-dummy { // 幅だけは正規で高さが1pxのデータを作っておく
    display: block;
    width: $item-width + 10px;
    min-width: $item-width + 10px;
    max-width: $item-width + 10px;
    min-height: 1px;
    max-height: 1px;
  }
}

$item-s-width: 160px;
#grid-small:checked {
  & ~ div.label-field {
    label.label-for-grid-small {
      color: blue;
    }
  }
  & ~ div.list-header { // ヘッダ非表示
    display: none;
  }
  & ~ div.d-flex .grid-or-list {
    display: block;
    padding: 3px 3px 3px 3px;
    width: $item-s-width + 6; // 画像によっては変な隙間が空くのでwidthを指定する

    .item-image-holder {
      display: block;
      position: relative;
      width: $item-s-width;
      height: $item-s-width;
      .item-image {
        display: inline;
        width: $item-s-width;
        height: $item-s-width;
        object-fit: cover;
        //&:hover {
        //  object-fit: cover;
        //}
      }
      .grid-favorite-star {
        position: absolute;
        top: 2px;
        right: 5px;
        font-size: 20px;
      }
      .grid-check-div {
        position: absolute;
        top: 0px;
        right: 10px;
        font-size: 12px;
        .form-check-label {
          padding-left: 0px;
        }
      }
    }
    // grid-smallの場合ラベル非表示
    span.item-list-label {
      display: none;
    }
    // $aの3番目がgrid-small用
    @each $c, $a in $item-all-cols {
      .item-#{$c} {
        display: nth($a, 3);
        max-width: $item-s-width;
      }
    } // @each
    // 商品名は最大２行だす
    .item-name {
      line-height: 20px; // 少し狭めに
      height: 40px;
      max-height: 40px;
      overflow: hidden;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  & ~ div.d-flex .grid-or-list-dummy { // 幅だけは正規で高さが1pxのデータを作っておく
    display: block;
    width: $item-s-width + 6px;
    min-width: $item-s-width + 6px;
    max-width: $item-s-width + 6px;
    min-height: 1px;
    max-height: 1px;
  }
}

// list表示用のstyle
#list:checked {
  & ~ div.label-field {
    label.label-for-list {
      color: blue;
    }
  }
  & ~ div.list-header {
    display: flex;
    position: sticky;
    top: 0;
    z-index:2;
    background-color: white;
    width: 100%;
    border-bottom: solid 1px #ddd;
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
  & ~ div.d-flex .grid-or-list {
    display: flex;
    width: 100%;
    border-bottom: solid 1px #ddd;
    font-size: 12px;
    @media only screen and (max-width: 1024px) {
      flex-wrap: wrap;
    }

    &:hover {
      background-color: #f5f5f5;
    }

    .item-image-holder { // 画像非表示
      display: none;
      .item-image {
        display: none;
      }
    }
    // listの時は項目毎のラベルは非表示
    span.item-list-label {
      display: none;
    }
    // $aの4番目がlist用
    @each $c, $a in $item-all-cols {
      .item-#{$c} {
        display: nth($a, 4);
        padding: 2.5px 5px 2.5px 5px;
      }
    } // @each
    // list表示する項目については個別に細かく設定が必要
    .item-no {
      padding: 2.5px 10px 2.5px 5px;
      text-align: right;
      width: 3rem;
      min-width: 3rem;
      max-width: 3rem;
      position: relative;
      @media only screen and (max-width: 1024px) {
        text-align: left;
        width: 8%;
        min-width: 8%;
        max-width: 8%;
      }
      span {
        position: absolute;
        z-index: 1;
        top: 2.5px;
        right: 10px;
        @media only screen and (max-width: 1024px) {
          position: static;
        }
      }
      .grid-favorite-star {
        position: absolute;
        top: 2px;
        left: 2px;
        font-size: 14px;
      }
    }
    .item-maker-name {
      width: 8rem;
      min-width: 8rem;
      max-width: 8rem;
    }
    .item-name {
      flex-grow: 1;
      @media only screen and (max-width: 1024px) {
        width: 42%;
        min-width: 42%;
        max-width: 42%;
      }
    }
    .item-model-number {
      width: 8rem;
      min-width: 8rem;
      max-width: 8rem;
    }
    .item-product-code {
      width: 8rem;
      min-width: 8rem;
      max-width: 8rem;
    }
    .item-spec {
      width: 8rem;
      min-width: 8rem;
      max-width: 8rem;
    }
    .item-use-unit {
      padding: 2.5px 16px 2.5px 0px;
      width: 8rem;
      min-width: 8rem;
      max-width: 8rem;
      text-align: right; // "1箱"だったり、"1箱5本入"だったりが混ざるとどうやっても揃わないけど右寄せがマシな気がした
    }
    .item-category-name {
      width: 6rem;
      min-width: 6rem;
      max-width: 6rem;
    }
    .item-patient-required {
      width: 6rem;
      min-width: 6rem;
      max-width: 6rem;
    }
    .item-maker-name,
    .item-model-number,
    .item-product-code,
    .item-spec,
    .item-use-unit,
    .item-patient-required {
      @media only screen and (max-width: 1024px) {
        width: 25%;
        min-width: 25%;
        max-width: 25%;
      }
    }
    [class^=item-] {
      @media only screen and (max-width: 1024px) {
        &::before {
          content: attr(data-content-label);
          color: #727272;
        }
      }
    }
  }
  & ~ div.d-flex .grid-or-list-dummy {
    display: none;
  }
}

// 商品詳細表示モーダル用のスタイル
div.item-for-modal {
  img.item-image {
    width: 340px;
    height: 340px;
    object-fit: cover;
    border: solid 1px silver;
    background-color: #eeeeee;
    //&:hover {
    //  object-fit: cover;
    //}
    @media only screen and (max-width: 1199px) {
      width: 230px;
      height: 230px;
    }
  }
  // carouselのindicatorの位置とデザインを変える
  ol.carousel-indicators {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    margin-left: 10px; // 中身のliが8件並ぶように
    margin-right: 10px; // 同上
    top: 350px; // img.item-imageのheight + 10pxぐらいが適正
    li {
      text-indent: 0;
      height: 30px;
      cursor: pointer;
      div {
        min-width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #999999;
        text-align: center;
        border-radius: 30px;
      }
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
      &.active {
        div {
          background-color: theme-color('navy');
          border-color: theme-color('navy');
          color: white;
        }
      }
    }
  }
}

div.breadcrumbs {
  a {
    color: blue;
    //color: theme-color(bs-primary);
  }
}

#item-search {
  .dropdown-header {
    display: none;
  }
  .dropdown-menu li a {
    padding: 0.5rem;
  }
}
