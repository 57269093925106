div.order-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
}

div.order-btn-header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 50;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}

// 削除ボタンにstickyを使う場合はその分の高さだけtopを指定する
// .form-headerを追加で設定しておく
div.order-header.form-header {
  top: 40px;
}

// 商品の情報と、部署ごとの情報を含んだdiv
div.order-item-wrapper {
  border-bottom: solid 1px #ddd;
  .purc-appl-rejected-item {
    background-color: lightgray;
  }
}

div.order-item-wrapper.search-target {
  background-color: rgba(255, 255, 0, 0.2);
  // border: 4px double #9966ff;
  .item-name > a, .item-model-number > a, .item-product-code > a, .item-spec > a {
    // border-bottom: 2px solid #ff3333;
    // margin: auto .4rem;
    // text-shadow: 1px 1px 0 rgba(0, 0, 0, .1);
    // font-size: 18px;
    // color: navy;
  }
  .grid-value, .grid-value-unit, .grid-value-total {
    // border-bottom: 2px solid #ff3333;
    // margin: auto .4rem;
    // text-shadow: 1px 1px 0 rgba(0, 0, 0, .1);
    // font-size: 18px;
    // color: navy;
  }
  .order-item-dept-amount {
    // border-bottom: 2px solid #ff3333;
    // margin: auto .4rem;
    // text-shadow: 1px 1px 0 rgba(0, 0, 0, .1);
    // font-size: 18px;
    // color: navy;
  }
}

// flexでは配置しきれないのでgridを使う
div.order-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;

  /* no, img, maker, name, model_number, product_code */
  padding: 0.5rem 0 0.5rem 0;
  margin: 2px 0px;
  font-size: 14px;
  .item-no {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    // margin: auto; // 写真を中央に配置
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }

  .order-details-div {
    grid-column: 1 / 9;
    grid-row: 4 / 6;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-top: 5px;
  
    .order-item-amount {
      grid-column: 1 / 2;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      padding-left: 3px;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .order-item-unit-number {
      grid-column: 2 / 3;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .order-item-unit-price {
      grid-column: 3 / 4;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .order-item-total-price {
      grid-column: 4 / 5;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;

      .grid-label {
        width: 40%;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: 60%;
        padding: 5px 15px 5px 5px;
        background-color: #fff;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }
  }

  .order-departments {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    word-break: break-all;
    padding-left: 3px;
    padding-top: 5px;

    .grid-label {
      width: 9.95%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 40.05%;
      padding: 0px;

      display: grid;
      grid-template-columns: repeat(1, 1fr);

      .order-department-item {
        display: flex;
        word-break: break-all;

        .grid-label-department {
          width: 75%;
          padding: 5px 0px 5px 5px;
          color: #666666;
          background-color: #f5f5f5;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;
        }
        .grid-value-department {
          width: 25%;
          padding: 5px 15px 5px 5px;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          display: flex;
          align-items: center;
          justify-content: right;
          background-color: #fff;
        }
      }

      // 繰り返す場合
      // grid-template-columns: repeat(3, 1fr);

      // .order-department-item:not(:nth-child(3n)) {
      //   display: flex;
      //   word-break: break-all;

      //   .grid-label-department {
      //     width: 75%;
      //     padding: 5px 0px 5px 5px;
      //     color: #666666;
      //     background-color: #f5f5f5;
      //     display: flex;
      //     align-items: center;
      //   }
      //   .grid-value-department {
      //     width: 25%;
      //     padding: 5px 15px 5px 5px;
      //     border-left: 1px solid #ddd;
      //     border-right: 1px solid #ddd;
      //     display: flex;
      //     align-items: center;
      //     justify-content: right;
      //     background-color: #fff;
      //   }
      // }
      // .order-department-item:nth-child(3n) {
      //   display: flex;
      //   word-break: break-all;

      //   .grid-label-department {
      //     width: 75%;
      //     padding: 5px 0px 5px 5px;
      //     color: #666666;
      //     background-color: #f5f5f5;
      //     display: flex;
      //     align-items: center;
      //   }
      //   .grid-value-department {
      //     width: 25%;
      //     padding: 5px 15px 5px 5px;
      //     border-left: 1px solid #ddd;
      //     display: flex;
      //     align-items: center;
      //     justify-content: right;
      //     background-color: #fff;
      //   }
      // }
      // .order-department-item:nth-child(n + 4) {
      //   .grid-label-department { border-top: 1px solid #ddd; }
      //   .grid-value-department { border-top: 1px solid #ddd; }
      // }
    }
  }
}

