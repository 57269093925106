div.comments-list {
  .comment-wrapper {
    border: 1px solid #CED4EA;
    border-radius: 5px;

    .base-field {
      display: flex;
      padding: 10px 20px 10px 10px;
      border-bottom: 1px solid #CED4EA;
      background: #f5f5f5;
    }
    .content-field {
      padding: 10px;
    }
  }
}

.v_line_fix {
  margin-left: 60px;
  display: inline-block;
  vertical-align: middle;
  color: #333;
  line-height: 1;
  position: relative;
  width: 0.1em;
  height: 1em;
  background: currentColor;

}

.v_line_fix::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border: 0.1em solid currentColor;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  position: absolute;
  left: 50%;
  bottom: -0.05em;
  box-sizing: border-box;
}