// 外部CSS
@import "vendor/material-dashboard-pro-html-v2.2.2/assets/scss/material-dashboard.scss";
@import "vendor/fontawesome-pro-6.0.0-beta1/scss/fontawesome.scss";
@import "vendor/jstree/themes/default/style.min";
@import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min";
// fullcalendarのjs内で読み込んでるcssが効いてないかんじなので、cssをインポートする
@import '@fullcalendar/common/main';
@import '@fullcalendar/daygrid/main';
@import '@fullcalendar/timegrid/main';
@import '@fullcalendar/list/main';
@import '@fullcalendar/bootstrap/main';
// bs-stepperのcssをインポートする
@import 'bs-stepper/dist/css/bs-stepper.min.css';

// 自作CSS
@import "scss/base.scss";
