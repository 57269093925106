div.receipt-header {
  position: sticky;
  top: 72px;
  z-index: 50;
  display: grid;
  grid-template-columns: 5.5rem 4.5rem 1fr 3fr 1fr 1fr 1fr 6rem;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column-start: 1;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column-start: 3;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column-start: 4;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column-start: 5;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column-start: 6;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column-start: 7;
    padding: 5px 5px 5px 5px;
  }
  .application-amount {
    grid-column-start: 8;
    padding: 5px 5px 5px 5px;
  }
  .application-note {
    display: none;
  }
}

// 商品の情報入力欄と、患者様入力欄を含んだdiv
div.receipt-item-wrapper {
  position: relative; // 緊急スタンプをabsoluteするために親をrelative
  border-bottom: solid 1px #ddd;
  &.date-border {
    border-bottom: solid 2px theme-color("bs-primary");
  }
  &.deadline-border {
    border-bottom: solid 2px theme-color("danger");
  }
  &.search-target {
    background-color: rgba(255, 255, 0, 0.2);
  }
  &.search-target.search-current {
    background-color: rgba(255, 128, 0, 0.2);
  }
}

// flexでは配置しきれないのでgridを使う
div.receipt-item-div {
  display: grid;
  //grid-template-columns: 3.5rem 4.5rem 1fr 3fr 1fr 1fr 6rem;
  grid-template-columns: 5.5rem 4.5rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 6rem;
  /* no, img, maker, name, model_number, product_code, spec, amount */
  padding: 0.5rem 0 0.5rem 0;
  font-size: 14px;
  .item-no {
    grid-column-start: 1;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    padding: 20px 5px 0px 5px;
    //text-align: center;
  }
  .item-image-holder {
    grid-column-start: 2;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    margin: 0 0.25rem;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column-start: 3;
    grid-column-end: 5;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column-start: 5;
    grid-column-end: 11;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column-start: 11;
    grid-column-end: 13;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column-start: 13;
    grid-column-end: 15;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column-start: 15;
    grid-column-end: 17;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .application-amount-text {
    grid-column-start: 17;
    font-size: 1.0rem;
    padding: 5px 0px;
    word-break: break-all;
  }

  .supp-appl-detail-info-div {
    grid-column: 1 / -1;
    grid-row: 3 / 4;

    padding-top: 5px;

    display: grid;
    grid-template-columns: repeat(6, 1fr) 6rem;

    // 各ラベルの幅を統一にするための変数
    // %で計算するとずれるので幅を割合ではなく数値にする
    // grid一つ分の20%とgrid二つ分の10%だと幅が異なる結果となる
    $grid-label-width: 5rem;

    .application-title {
      grid-column: 1 / -1;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      padding-left: 3px;

      .grid-label {
        width: $grid-label-width;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: calc(100% - #{$grid-label-width});
        padding: 5px 15px 5px 15px;
        border-top: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: left;
      }

    }
    .application-user-name {
      grid-column: 1 / 4;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      padding-left: 3px;

      .grid-label {
        width: $grid-label-width;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: calc(100% - #{$grid-label-width});
        padding: 5px 15px 5px 15px;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: left;
      }
    }
    .application-department-name {
      grid-column: 4 / 7;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;

      .grid-label {
        width: $grid-label-width;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: calc(100% - #{$grid-label-width});
        padding: 5px 15px 5px 15px;
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
        justify-content: left;
      }
    }
    .application-status {
      grid-column: 7 / 8;
      padding: 5px 5px 5px 5px;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .application-detail-note {
      grid-column: 1 / -1;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      padding-left: 3px;

      .grid-label {
        width: $grid-label-width;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: calc(100% - #{$grid-label-width});
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        padding: 5px 15px 5px 15px;
        display: flex;
        align-items: center;
        justify-content: left;
        p {
          margin: 0;
        }
      }
    }

    .patients-div {
      grid-column: 1 / -1;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      padding-left: 3px;
      .grid-label {
        width: $grid-label-width;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: calc(100% - #{$grid-label-width});
        border-right: 1px solid #93B8B6;
        border-bottom: 1px solid #93B8B6;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        .patient-header {
          color: #666666;
          background-color: #f5f5f5;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;
        }

        .patient-info-use-term {
          .patient-name {
            padding: 5px 10px 5px 10px;
            border-right: 1px solid #ddd;
            width: 25%;
          }
          .patient-id {
            padding: 5px 10px 5px 10px;
            border-right: 1px solid #ddd;
            width: 20%;
          }
          .patient-amount {
            padding: 5px 10px 5px 5px;
            border-right: 1px solid #ddd;
            width: 12%;
          }
          .patient-use-term {
            padding: 5px 5px 5px 5px;
            width: 43%;
          }
        }

        .patient-info-no-use-term {
          .patient-name {
            padding: 5px 10px 5px 10px;
            border-right: 1px solid #ddd;
            width: 40%;
          }
          .patient-id {
            padding: 5px 10px 5px 10px;
            border-right: 1px solid #ddd;
            width: 40%;
          }
          .patient-amount {
            padding: 5px 10px 5px 10px;
            width: 20%;
          }
        }

        .patient-body {
          display: flex;
          align-items: center;
        }

        // 左右の患者様情報の間に区切り線をつける
        .patient-header:nth-child(2n + 1),.patient-body:nth-child(2n + 1) {
          border-right: 3px double #ddd;
        }
        // 上下の患者様情報の間に区切り線をつける
        .patient-body:nth-child(n) {
          border-bottom: 1px solid #ddd;
        }
      }
    }
    .supp-appl-receipt-info {
      grid-column: 1 / -1;
      // ラベルと、値を横並びにするためにflex
      display: flex;
      word-break: break-all;
      padding-left: 3px;

      .line-through {
        text-decoration-line: line-through;
      }

      .grid-label {
        width: $grid-label-width;
        padding: 5px 0px 5px 5px;
        background-color: #DBE6F0;
        border-bottom: 1px solid #93B8B6;
        border-left: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: flex;
        align-items: center;
      }

      .grid-value {
        width: calc(100% - #{$grid-label-width});
        border-bottom: 1px solid #93B8B6;
        border-right: 1px solid #93B8B6;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        .receipt-details {
          grid-column: 1 / -1;
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
        .receipt-info-header {
          color: #666666;
          background-color: #f5f5f5;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;
        }
        .receipt-name {
          padding: 5px 10px 5px 10px;
          border-right: 1px solid #ddd;
          width: calc(100% - 4rem - 8rem - 6rem - 4rem);
        }
        .receipt-amount {
          padding: 5px 10px 5px 5px;
          border-right: 1px solid #ddd;
          width: 4rem;
        }
        .receipt-received-at {
          padding: 5px 10px 5px 5px;
          border-right: 1px solid #ddd;
          width: 8rem;
        }
        .receipt-status {
          padding: 5px 10px 5px 5px;
          border-right: 1px solid #ddd;
          width: 6rem;
        }
        .receipt-btn-div {
          width: 4rem;
        }

        .receipt-body {
          display: flex;
          align-items: center;
        }
        .receipt-body.deprecated {
          background-color: lightgray;
        }

        // 左右の患者様情報の間に区切り線をつける
        .receipt-info-header:nth-child(2n + 1),.receipt-body:nth-child(2n + 1) {
          border-right: 3px double #ddd;
        }
        // 上下の患者様情報の間に区切り線をつける
        .receipt-body:nth-child(n) {
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }
}

.detail-urgent-stamp {
  position: absolute;
  top: 5px;
  width: 40px;
  text-align: center;
  z-index: 40;
  font-size: 14px;
  color: theme-color(danger);
  border-style: solid;
  border-width: 2px;
  border-color: theme-color(danger);
  border-radius: 5px;
  padding: 0px;
  transform: rotate(-30deg);
}
