
div.supp-appl-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 3fr 1fr 1fr 1fr 9.5rem;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    padding: 5px 5px 5px 5px;
  }
  .application-amount {
    padding: 5px 5px 5px 5px;
    text-align: center;
  }
  .operation-area { // editでのみ使う
    padding: 5px 15px 5px 15px;
    grid-column-start: 9; // これが発生した時だけ普段は存在しない9番目のグリッドを使う
    grid-row-start: 1;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
  .application-note {
    display: none;
  }
}

div.supp-appl-btn-header {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 50;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

// 削除ボタンにstickyを使う場合はその分の高さだけtopを指定する
// .form-headerを追加で設定しておく
div.supp-appl-header.form-header {
  top: 40px;
}

// 商品の情報入力欄と、患者様入力欄を含んだdiv
div.supp-appl-item-wrapper {
  border-bottom: solid 1px #ddd;
  .supp-appl-rejected-item {
    background-color: lightgray;
  }
}

// flexでは配置しきれないのでgridを使う
div.supp-appl-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 3fr 1fr 1fr 1fr 9.5rem;
  /* no, img, maker, name, model_number, product_code, amount */
  padding: 0.5rem 0 0.5rem 0;
  font-size: 14px;
  .item-no {
    grid-column-start: 1;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column-start: 2;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column-start: 3;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column-start: 4;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    //min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    //max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column-start: 5;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column-start: 6;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column-start: 7;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .application-amount { // editでのみ使う
    // 入力フォームと使用入数を縦並びにして尚且つ上下中央にする
    display: flex;
    flex-flow: column;
    justify-content: center;
    grid-column-start: 8;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 0px 0px;
    input {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    // dropdownがでかすぎるのを調整
    button {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      border-color: #ced4da;
      box-shadow: none;
      padding: 7px !important;
      //padding-left: 0.4rem !important;
      //padding-right: 0.4rem !important;
    }
    .dropdown-menu {
      min-width: 2rem !important;
      .dropdown-item {
        text-align: right;
        padding: 5px;
        min-width: 2rem !important;
        justify-content: center;
      }
    }
  }
  .application-amount-text { // 確認画面でのみ使う
    grid-column-start: 8;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 5px 5px 5px 5px;
  }

  .application-note {
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row-start: 2;
    padding: 0 5px;
  }
  .application-note-add {
    grid-column-start: 7;
    grid-column-end: 8;
    grid-row-start: 2;
    padding: 0;
    a {
      display: block;
      width: 100%;
      height: 100%;
      padding-top: 5px;
    }
  }
  .operation-area { // editでのみ使う
    padding: 5px 15px 5px 15px;
    grid-column-start: 9; // これが発生した時だけ普段は存在しない9番目のグリッドを使う
    grid-row-start: 1;
    grid-row-end: 3;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
}

.urgent-stamp {
  position: absolute;
  top: 30px;
  left: 100px;
  z-index: 40;
  font-size: 2rem;
  color: theme-color(danger);
  border-style: solid;
  border-width: 2px;
  border-color: theme-color(danger);
  border-radius: 5px;
  padding: 5px;
  transform: rotate(-30deg);
}

div.patients-row {
  padding: 0.5rem 0 0.5rem 0;
}

.supp-appl-item-wrapper {
  .patient-header {
    color: #666666;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  @media screen and (min-width: 576px) {
    // 左右の患者様情報の間に区切り線をつける
    .patient-header:nth-child(2n + 1),.patient-field-row:nth-child(2n + 1) {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
    .patient-header:nth-child(2n),.patient-field-row:nth-child(2n) {
      border-right: 1px solid #ddd;
    }

    // 上下の患者様情報の間に区切り線をつける
    .patient-field-row {
      border-bottom: 1px solid #ddd;
    }
  }

  @media screen and (max-width: 575px) {
    .patient-field-row {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
  }
}

// 押印欄
div.stamp-card {
  border: solid 1px black;
  min-width: 6rem;
  text-align: center;
  margin-left: -1px;
}
div.stamp-header {
  border-bottom: solid 1px black;
  min-height: 1.5rem;
}
div.stamp-body {
  min-height: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
// 自動承認の場合は斜線を引くのでそれようのデザインを用意
div.stamp-body.slashed {
  background-image: linear-gradient(
      to bottom right,
      transparent,
      transparent 49.2%, black 49.7%, // この二つの値を若干変えることでギザギザを抑える
      black 50.3%, transparent 50.8%, // この二つの値を若干変えることでギザギザを抑える
      transparent);
}


div.supp-appl-applied-items-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 3rem 2fr 1fr 7rem 8rem 2fr;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .no {
    grid-column: 1 / 2;
    padding: 5px;
  }
  .supp-appl-title {
    grid-column: 2 / 3;
    padding: 5px;
  }
  .user-name {
    grid-column: 3 / 4;
    padding: 5px;
  }
  .amount {
    grid-column: 4 / 5;
    padding: 5px;
  }
  .applied-at {
    grid-column: 5 / 6;
    padding: 5px;
  }
  .note {
    grid-column: 6 / -1;
    padding: 5px;
  }
}

div.supp-appl-applied-items-item-div {
  display: grid;
  grid-template-columns: 3rem 2fr 1fr 7rem 8rem 2fr;

  margin: 2px 0px;
  font-size: 14px;
  border-bottom: 1px solid #93B8B6;

  .no {
    grid-column: 1 / 2;
    grid-row: 1;
    padding: 5px;
    text-align: center;
  }

  .supp-appl-title {
    grid-column: 2 / 3;
    padding: 5px;
    word-break: break-all;
  }

  .user-name {
    grid-column: 3 / 4;
    padding: 5px;
    word-break: break-all;
  }
  .amount {
    grid-column: 4 / 5;
    grid-row: 1;
    padding: 5px 30px 5px 5px;
    text-align: right;
    word-break: break-all;
  }
  .applied-at {
    grid-column: 5 / 6;
    grid-row: 1;
    padding: 5px;
    word-break: break-all;
  }
  .note {
    grid-column: 6 / -1;
    grid-row: 1;
    padding: 5px;
    word-break: break-all;
  }
}
