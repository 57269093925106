div.delivery-qrcode-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
}

div.delivery-qrcode-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;

  /* no, maker, name, model_number, product_code, expired_at unit_number amount qrcode_amount */
  padding: 0.5rem 0 0.5rem 0;
  margin: 2px 0px;
  font-size: 14px;
  .item-no {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    // margin: auto; // 写真を中央に配置
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }

}

div.delivery-qrcode-details-div {
  grid-column: 1 / -1;
  grid-row: 2 / 4;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .stock-expired-at {
    grid-column: 1 / 2;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;
    word-break: break-all;

    .grid-label {
      width: 30%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      text-align: right;
      padding: 5px 15px 5px 5px;
      width: 70%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }
  .item-unit-number {
    grid-column: 2 / 3;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;
    word-break: break-all;

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }
    .grid-value {
      text-align: right;
      padding: 5px 15px 5px 5px;
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }
  .stock-amount {
    grid-column: 3 / 4;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;
    word-break: break-all;

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }
  .stock-qrcode-amount {
    grid-column:  4 / 5;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: flex-end;
    word-break: break-all;

    .grid-label {
      width: 40%;
      padding: 5px 0px 5px 5px;
      background-color: #DBE6F0;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-left: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
    }

    .grid-value {
      text-align: right;
      padding: 5px 15px 5px 5px;
      width: 60%;
      padding: 5px 15px 5px 5px;
      background-color: #fff;
      border-top: 1px solid #93B8B6;
      border-bottom: 1px solid #93B8B6;
      border-right: 1px solid #93B8B6;
      display: flex;
      align-items: center;
      justify-content: right;
    }
  }
}
