@import "jquery-ui/themes/base/base.css";

// autocompleteのセレクトボックスのCSS
// 読み込むCSSが足りてないからかこれがないと背景が透明でマウスオーバーしてもわからない
.ui-autocomplete.ui-widget-content {
  border: 1px solid #c5c5c5;
  background: #ffffff;
  color: #333333;
  list-style-type: none;
}
.ui-autocomplete .ui-state-active {
  border: 1px solid #003eff;
  background: #007fff;
  font-weight: normal;
  color: #ffffff;
}
