// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty
    padding-right: ($gutter / 2);
    padding-left: ($gutter / 2);
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints); // -sd とか -md とか

    // Allow columns to stretch full width below their breakpoints
    @for $i from 1 through $columns { // 1-12までのループ
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
      // ここに追加で$i-1に対して_25, _50, _75を追加する
      // 最小はcol-0_25、最大はcol-11_75となる
      @each $fct in (25 50 75) {
        .col#{$infix}-#{$i - 1}_#{$fct} {
          @extend %grid-column;
        }
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
        // ここに追加でも$i-1に対して_25, _50, _75を追加する
        @each $fct in (25 50 75) {
          $tick: 1 / ($columns * 4); // 最小幅
          $times: ($i - 1) * 4 + ($fct / 25); // 最小幅の何倍か
          .col#{$infix}-#{$i - 1}_#{$fct} {
            flex: 0 0 percentage($tick * $times);
            max-width: percentage($tick * $times);
          }
        }
      }

      .order#{$infix}-first { order: -1; }

      .order#{$infix}-last { order: $columns + 1; }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} { order: $i; }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}
