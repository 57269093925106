#powerful-search {
  .input-container {
    margin: 0;
    margin-bottom: 5px;
    padding-top: 12px;
    position: relative;
  }
  .input-container label {
    position: absolute;
    top: 17px;
    left: 18px;
    color: #aaa;
    transition: all 0.2s;
    cursor: text;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 24px;
    pointer-events: none; // inputタグの上にlabelがあるとfocusが当たらないので
  }
  .input-container input {
    padding: 4px 10px;
    // border: none;
    border-bottom: 1px solid #ccc;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: #555;
    -webkit-font-smoothing: antialiased;
    outline: none;
    width: 100%;
  }
  .input-container span.is-focused, .input-container span.is-filled {
    border-bottom: 1px solid #aaa;
  }
  .input-container span.is-focused + label, .input-container span.is-filled + label {
    font-size: 11px;
    line-height: 12px;
    top: 0px;
    left: 5px;
    color: #777;
  }
}