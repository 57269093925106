// 参考(https://qiita.com/tsmd/items/fce7bf1f65f03239eef0)

.FlexTextarea {
  position: relative;
}

.FlexTextarea__dummy {
  overflow: hidden;
  visibility: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.42857 !important; // textareaは1.42857となっているので合わせる
}

.FlexTextarea__textarea {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  width: 100% !important;
  height: 100% !important;
  letter-spacing: inherit;
  resize: none;
}
