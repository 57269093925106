// 在庫一覧の幅
// 権限や検索条件によって表示するカラムやカラムの幅が変わるので，それぞれで定義
// jsでtableタグのクラスを変更することで，スタイルを変更する

// デフォルト(全部署の場合)
#stocks.table.datatables {
  // jsのdatatableのcolumnsにはclassNameにw-<hoge>を指定する
  $width_defs: (
    maker: 5rem,
    model-number: 5rem,
    product-code: 6rem,
    // category: 5rem,
    spec: 5rem,
    fd: 10rem,
    amount: 5rem,
    latest-applied-at: 5rem,
    item-updated-at: 5rem,
  );
  $sum: 0;
  @each $col_name, $width in $width_defs {
    $sum: $sum + $width;
    // jsのdatatableのcolumnsにはclassNameにw-<hoge>を指定する
    .w-#{$col_name} {
      // NOTE: maxとmin両方指定しないと検索結果によってカラムの幅が変わってしまう
      width: $width;
      max-width: $width;
      min-width: $width;
    }
  }
  .w-item {
    max-width: calc(80vw - 260px - 3rem - #{$sum});
  }
}

// 部署が指定されている場合
#stocks.table.datatables.dept-selected {
  // jsのdatatableのcolumnsにはclassNameにw-<hoge>を指定する
  $width_defs: (
    maker: 5rem,
    model-number: 5rem,
    product-code: 5rem,
    // category: 6rem,
    // $w_fd: 0rem; //部署が指定されているので部署カラムはいらない
    amount: 5rem,
    latest-applied-at: 5rem,
    item-updated-at: 5rem,
  );
  $sum: 0;
  @each $col_name, $width in $width_defs {
    $sum: $sum + $width;
    // jsのdatatableのcolumnsにはclassNameにw-<hoge>を指定する
    .w-#{$col_name} {
      // NOTE: maxとmin両方指定しないと検索結果によってカラムの幅が変わってしまう
      width: $width;
      max-width: $width;
      min-width: $width;
    }
  }
  .w-item {
    max-width: calc(80vw - 260px - 3rem - #{$sum});
  }
}
