div.transfer-header {
  position: sticky;
  top: 0;
  z-index: 50;
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 2.5fr 1fr 1fr 1fr 7.5rem;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    padding: 5px 5px 5px 5px;
  }
  .transfer-amount {
    padding: 5px 5px 5px 5px;
  }
}

// flexでは配置しきれないのでgridを使う
div.transfer-item-div {
  display: grid;
  border-bottom: solid 1px #ddd;
  grid-template-columns: 2rem 4rem 1fr 2.5fr 1fr 1fr 1fr 7.5rem;
  /* no, img, maker, name, model_number, product_code, spec */
  padding: 0.5rem 0 0.5rem 0;
  font-size: 14px;
  .item-no {
    grid-column-start: 1;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column-start: 2;
    grid-row-start: 1; // ２行分
    grid-row-end: 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column-start: 3;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column-start: 4;
    padding: 5px 5px 5px 5px;
    //min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    //max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column-start: 5;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column-start: 6;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column-start: 7;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .transfer-amount { // editでのみ使う
    grid-column-start: 8;
    grid-row-start: 2;
    padding: 0px 0px;
    display: flex;
    .amount-input {
      input {
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 3px;
        padding-right: 3px;
        max-width: 4.5rem;
      }
    }
    .amount-unit-name {
      padding-top: 2px;
      padding-left: 2px;
    }
  }
  .transfer-amount-text { // 確認画面でのみ使う
    grid-column-start: 8;
    grid-row-start: 1;
    grid-row-end: 3;
    font-size: 1.0rem;
    padding: 0px 0px;
  }
  .operation-area { // editでのみ使う
    text-align: center;
    grid-column-start: 8;
    grid-row-start: 1;
    a {
      display: block;
      width: 100%;
      height: 100%;
      padding-top: 5px;
    }
  }
}

div.response-transfer-header {
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;
  font-size: 14px;
  border-bottom: solid 1px #ddd;
  background-color: #f5f5f5;
  .item-no {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
  }
}

// flexでは配置しきれないのでgridを使う
div.response-transfer-item-div {
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;
  border-bottom: solid 1px #ddd;

  /* no, img, maker, name, model_number, product_code */
  padding: 0.5rem 0 0.5rem 0;
  margin: 2px 0px;
  font-size: 14px;
  .item-no {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    padding: 20px 5px 0px 0px;
    text-align: center;
  }
  .item-image-holder {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    img.item-image {
      // 画像サイズはgridの幅に合わせる
      width: 4rem;
      height: 4rem;
      object-fit: cover;
    }
  }
  .item-maker-name {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-name {
    grid-column: 4 / 6;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    //min-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    //max-width: calc(100% - 3rem - 8rem - 8rem - 8rem - 6rem);
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-model-number {
    grid-column: 6 / 7;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-product-code {
    grid-column: 7 / 8;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }
  .item-spec {
    grid-column: 8 / 9;
    grid-row: 1 / 2;
    padding: 5px 5px 5px 5px;
    word-break: break-all;
    // text-overflow: ellipsis;
    // overflow-x: hidden;
    // white-space: nowrap;
  }

  .transfer-request-amount {
    grid-column: 5 / 6;
    grid-row: 2 / 3;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;

    .grid-label {
      padding: 5px 0px 5px 5px;
      color: #666666;
    }

    .grid-value {
      text-align: right;
      padding: 5px 15px 5px 5px;
    }
  }

  .transfer-exist-amount {
    grid-column: 6 / 7;
    grid-row: 2 / 3;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;

    .grid-label {
      padding: 5px 0px 5px 5px;
      color: #666666;
    }

    .grid-value {
      text-align: right;
      padding: 5px 15px 5px 5px;
    }
  }

  .transfer-response-amount {
    grid-column: 7 / 8;
    grid-row: 2 / 3;
    // ラベルと、値を横並びにするためにflex
    display: flex;
    justify-content: space-between;

    .grid-label {
      padding: 5px 0px 5px 5px;
      color: #666666;
    }

    .grid-value {
      text-align: right;
      padding: 5px 15px 5px 5px;
    }
  }
  .operation-area {
    grid-column: 8 / 9;
    grid-row: 2 / 3;
    text-align: right;
    padding: 5px 5px 5px 5px;
  }
}

div.response-transfer-expired-at-div {
  background-color: #f5f5f5;
  border-bottom: solid 1px #ddd;
  display: grid;
  grid-template-columns: 2rem 4rem 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 0.5rem 0;

  .expired-at-title {
    grid-column: 1 / 3;
    padding: 5px 5px 5px 5px;
    color: #666666;
  }
  .expired-at-values {
    grid-column: 3 / -1;
    // 使用期限を2カラム構成にする
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .expired-at-value {
      // 使用期限の中身もグリッドにする
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      .expired-at-text {
        grid-column: 1 / 2;
        padding: 5px 5px 5px 5px;
      }
      .expired-at-amount {
        grid-column: 2 / 3;
        padding: 5px 15px 5px 5px;
        text-align: right;
      }
    }

    .expired-at-field { // editのときにつかう
      // 使用期限の中身もグリッドにする
      display: grid;
      grid-template-columns: 6rem 4rem 2fr 4rem 3fr;

      .expired-at-text {
        padding: 5px 5px 5px 5px;
      }
      .exist-amount-label {
        color: #666666;
        padding: 5px 5px 5px 5px;
      }
      .exist-amount-value {
        padding: 5px 15px 5px 5px;
        text-align: right;
      }
      .transfer-amount-label {
        color: #666666;
        padding: 5px 5px 5px 5px;
      }
      .transfer-amount-value {
        padding: 5px 15px 5px 5px;
        text-align: right;
      }
      .transfer-amount-field { // editのときのみ
        padding: 2px 0px;
        display: flex;
        .amount-input {
          input {
            padding-top: 3px;
            padding-bottom: 3px;
            padding-left: 3px;
            padding-right: 3px;
            max-width: 4.5rem;
          }
        }
        .amount-unit-name {
          padding-top: 2px;
          padding-left: 2px;
        }
      }
    }
  }
}
